.blog-article {
	position: relative;
	min-height: 658px;
	padding: 40px 120px 40px 32px;
	margin-top: 1px;
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		height: 658px;
		width: 100%;
		z-index: -1;
		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(to bottom, rgba($colorDarkBg, 0) 0%, rgba($colorDarkBg, 1) 100%);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			pointer-events: none;
			opacity: 1;
			transition: opacity 1s ease-in;
		}
		&:after {
			content: '';
			position: absolute;
			background: linear-gradient(to bottom, rgba($colorBgGrey, 0) 0%, rgba($colorBgGrey, 1) 100%);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			pointer-events: none;
			opacity: 0;
			transition: opacity 1s ease-out;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center top;
			opacity: 0.5;
		}
	}
	&__back {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 78px;
		}
		a {
			position: relative;
			display: inline-flex;
			align-items: center;
			color: $colorWhite;
			font-size: 12px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
			letter-spacing: 0.31em;
			text-transform: uppercase;
			transition: color 1s ease-in-out;
			&:hover {
				color: $colorGreen;
				transition: color 0.3s ease;
				.trapeze {
					width: 40px;
				}
			}
		}
	}
	&__head {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 110px;
		}
	}
	&__category {
		position: relative;
		color: $colorGrey;
		font-size: 12px;
		font-weight: 700;
		font-family: 'Roboto', sans-serif;
		line-height: 1.16667;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-left: 10px;
		transition: color 1s ease-in-out;
		&:not(:last-child) {
			margin-bottom: 14px;
		}
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 1px;
			left: 0;
			bottom: 3px;
			width: 2px;
		}
	}
	&__title {
		position: relative;
		max-width: 770px;
		font-size: 38px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 14px;
		}
		h1 {
			font-weight: 600;
		}
		a {
			color: $colorWhite;
			transition: color 1s ease-in-out;
			&:hover {
				color: $colorGreen;
				transition: color 0.3s ease;
			}
		}
	}
	&__date {
		position: relative;
		color: $colorGrey;
		font-size: 12px;
		font-weight: 700;
		font-family: 'Roboto', sans-serif;
		line-height: 1.16667;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-left: 10px;
		transition: color 1s ease-in-out;
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 1px;
			left: 0;
			bottom: 3px;
			width: 2px;
		}
	}
	&__body {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
		&__content {
			//position: relative;
			width: 546px;
		}
		&__text {
			//position: relative;
			color: $colorGrey;
			font-size: 14px;
			line-height: 1.6;
			text-align: justify;
			padding-bottom: 40px;
			transition: color 1s ease-in-out;
			h2 {
				color: $colorWhite;
				font-size: 28px;
				font-weight: 600;
				line-height: 1.3;
				text-align: left;
				transition: color 1s ease-in-out;
				&:not(:first-child) {
					margin-top: 50px;
				}
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
			b, strong {
				font-weight: 400;
				transition: color 1s ease-in-out;
			}
			a {
				color: $colorGreen;
			}
			blockquote {
				position: relative;
				color: $colorWhite;
				font-size: 18px;
				font-weight: 600;
				line-height: 1.3;
				text-align: left;
				padding-top: 36px;
				margin: 0;
				transition: color 1s ease-in-out;
				&:not(:first-child) {
					margin-top: 50px;
				}
				&:not(:last-child) {
					margin-bottom: 50px;
				}
				&:before {
					content: '\EA20';
					position: absolute;
					top: 0;
					left: 0;
					color: $colorGreen;
					font-size: 32px;
					font-family: 'svgfont', sans-serif;
					line-height: 1;
				}
			}
		}
		&__next {
			position: relative;
			padding: 40px 0;
			&:before {
				content: '';
				position: absolute;
				background: $colorLightBg;
				top: 0;
				left: -32px;
				right: 0;
				height: 1px;
				transition: opacity 1s ease-in-out;
			}
			&:after {
				content: '';
				position: absolute;
				background: $colorLightBg;
				left: -32px;
				right: 0;
				bottom: 0;
				height: 1px;
				transition: opacity 1s ease-in-out;
			}
			.blog-article {
				&__title {
					max-width: 340px;
					font-size: 18px;
				}
			}
		}
	&__other {
		position: absolute;
		top: 50%;
		right: 0;
		width: 340px;
		text-align: left;
		transform: translateY(-50%);
		&-heading {
			position: relative;
			color: $colorWhite;
			font-size: 23px;
			font-weight: 600;
			line-height: 1.3;
			padding-top: 20px;
			transition: color 1s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 0;
				left: 0;
				width: 44px;
				height: 2px;
			}
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		&-img {
			position: relative;
			&:not(:last-child) {
				margin-bottom: 20px;
			}
			a {
				display: block;
				line-height: 0;
			}
		}
		&-category {
			position: relative;
			color: $colorGrey;
			font-size: 12px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			padding-left: 10px;
			transition: color 1s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 1px;
				left: 0;
				bottom: 3px;
				width: 2px;
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		&-title {
			position: relative;
			font-size: 18px;
			font-weight: 600;
			line-height: 1.3;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
			a {
				color: $colorWhite;
				transition: color 1s ease-in-out;
				&:hover {
					color: $colorGreen;
					transition: color 0.3s ease;
				}
			}
		}
		&-date {
			position: relative;
			color: $colorGrey;
			font-size: 12px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			padding-left: 10px;
			transition: color 1s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 1px;
				left: 0;
				bottom: 3px;
				width: 2px;
			}
		}
	}
}
