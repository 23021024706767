body {
	&.light {
		background: $colorBgGrey;
		color: $colorDarkBg;
		&::-webkit-scrollbar-track {
			background: rgba($colorLightBg, 0.2);
		}
		&::-webkit-scrollbar-thumb {
			background: $colorLightBg;
		}
		.btn {
			color: $colorDarkBg;
			&:hover {
				color: $colorGreen;
				.btn {
					&-label {
						color: $colorDarkBg;
					}
				}
			}
			&-label {
				background: $colorBgGrey;
			}
			&-grey {
				background: rgba($colorDarkBg, 0.08);
				border-color: rgba($colorDarkBg, 0.8);
				.btn {
					&-title {
						border-color: rgba($colorDarkBg, 0.8);
						&:before {
							background: rgba($colorDarkBg, 0.8);
						}
					}
				}
			}
			&-min,
			&-max {
				&:hover {
					color: $colorDarkBg;
				}
			}
		}
		.link {
			color: $colorDarkBg;
			&:hover {
				color: $colorGreen;
			}
		}
		.trapeze {
			&:after {
				border-color: transparent $colorBgGrey $colorBgGrey transparent;
			}
		}
		.bg {
			&-default {
				background: $colorBgGrey;
			}
		}
		input,
		textarea {
			color: $colorDarkBg;
		}
		.select {
			&-modal {
				&__search {
					background: $colorWhite;
				}
				&__result {
					background: $colorWhite;
				}
				&__box {
					&:before {
						background: $colorDarkBg;
					}
					&-title  {
						color: rgba($colorDarkBg, 0.8);
						b, strong {
							color: $colorDarkBg;
						}
					}
				}
				&__button {
					&:before {
						opacity: 0.2;
					}
					.b-left {
						color: $colorDarkBg;
						&:hover {
							color: $colorGreen;
						}
					}
				}
			}
		}
		.form {
			&-group {
				label {
					color: rgba($colorDarkBg, 0.5);
				}
			}
			&-multiple {
				background: $colorWhite;
				.tabs {
					ul {
						background: $colorBgGrey;
					}
				}
			}
			&-select {
				&.select-flag {
					.form-select {
						&__scroll {
							ul {
								li {
									color: $colorDarkBg;
									&:before {
										background: $colorDarkBg;
									}
								}
							}
						}
					}
				}
				&__box {
					background: $colorWhite;
					&.show {
						border-color: rgba($colorLightBg, 0.2);
						&:before {
							background: rgba($colorLightBg, 0.2);
						}
					}
				}
				&__list {
					ul {
						li {
							color: rgba($colorDarkBg, 0.5);
							border-color: rgba($colorLightBg, 0.2);
							&:hover {
								color: $colorDarkBg;
							}
							&.active {
								color: $colorDarkBg;
							}
						}
					}
				}
			}
			&-search {
				&__box {
					background: $colorWhite;
				}
				&__toggle {
					input {
						color: $colorDarkBg;
						&::-webkit-input-placeholder {
							color: rgba($colorDarkBg, 0.5);
						}
						&::-moz-placeholder {
							color: rgba($colorDarkBg, 0.5);
						}
						&:-ms-input-placeholder {
							color: rgba($colorDarkBg, 0.5);
						}
						&:-moz-placeholder  {
							color: rgba($colorDarkBg, 0.5);
						}
					}
				}
				&__list {
					ul {
						li {
							color: rgba($colorDarkBg, 0.5);
							border-color: rgba($colorLightBg, 0.2);
							&:hover {
								color: $colorDarkBg;
							}
							&.active {
								color: $colorDarkBg;
							}
						}
					}
				}
			}
		}
		.switch {
			[type="checkbox"] {
				& ~ label {
					color: rgba($colorDarkBg, 0.5);
					&:before {
						background: $colorWhite;
						border-color: rgba($colorDarkBg, 0.5);
					}
					&:after {
						background: $colorDarkBg;
					}
					&:hover {
						color: $colorDarkBg;
					}
				}
				&:checked {
					& ~ label {
						color: $colorGreen;
						&:before {
							border-color: $colorGreen;
						}
						&:hover {
							color: $colorDarkBg;
						}
					}
				}
			}
		}
		.overflow {
			//background: $colorBgGrey;
		}
		.modal {
			background: rgba($colorBgGrey, 0.9);
			&::-webkit-scrollbar-track {
				background: rgba($colorLightBg, 0.2);
			}
			&::-webkit-scrollbar-thumb {
				background: $colorLightBg;
			}
			&-body {
				background: $colorWhite;
			}
			&-text {
				color: rgba($colorDarkBg, 0.8);
				table {
					thead {
						tr {
							th, td {
								color: rgba($colorDarkBg, 0.8);
							}
						}
					}
					tr {
						th, td {
							color: $colorDarkBg;
							.price {
								color: $colorDarkBg;
							}
						}
					}
				}
			}
			&-link {
				.b-left {
					color: $colorDarkBg;
					&:hover {
						color: $colorGreen;
					}
				}
			}
			.form {
				&-select {
					&__box {
						background: $colorBgGrey;
						.mCSB_scrollTools {
							.mCSB_draggerRail {
								background: rgba($colorDarkBg, 0.1);
							}
						}
					}
				}
			}
			.zap {
				&-form {
					&__field {
						background: $colorBgGrey;
					}
				}
			}
		}
		.range-slider {
			&__line {
				background: rgba($colorDarkBg, 0.8);
				&:after {
					background: rgba($colorDarkBg, 0.8);
				}
			}
		}
		.menu-toggle,
		.menu-close {
			background: $colorWhite;
			&:after {
				border-color: transparent $colorBgGrey $colorBgGrey transparent;
			}
		}
		.table {
			&-heading {
				span {
					&:before {
						opacity: 0.2;
					}
				}
			}
			&-info {
				color: rgba($colorDarkBg, 0.8);
			}
			&-label {
				color: rgba($colorDarkBg, 0.5);
			}
			&-line {
				&:not(:last-child) {
					border-color: rgba($colorLightBg, 0.2);
				}
			}
		}
		.header {
			background: $colorBgGrey;
			&-metamask {
				a {
					color: $colorDarkBg;
					&:hover {
						color: $colorGreen;
					}
				}
				&__label {
					color: rgba($colorDarkBg, 0.8);
				}
			}
			&-connect {
				a {
					color: $colorDarkBg;
					&:hover {
						color: $colorGreen;
					}
				}
			}
			&-account {
				color: rgba($colorDarkBg, 0.8);
				b {
					color: $colorDarkBg;
				}
			}
		}
		.logo {
			position: relative;
			.logo {
				&-light {
					opacity: 0;
				}
				&-dark {
					opacity: 1;
				}
			}
		}
		.sidebar {
			&-menu {
				& > ul {
					& > li {
						background: $colorWhite;
						&.show {
							border-color: rgba($colorLightBg, 0.2);
						}
						&:after {
							background: rgba($colorLightBg, 0.2);
							border-color: transparent $colorLightBg $colorLightBg transparent;
						}
						& > a {
							color: rgba($colorDarkBg, 0.5);
							&:hover {
								color: $colorDarkBg;
							}
							&.active {
								color: $colorDarkBg;
							}
						}
						ul {
							li {
								a {
									color: rgba($colorDarkBg, 0.5);
									border-color: rgba($colorLightBg, 0.1);
									&:hover {
										color: $colorDarkBg;
									}
									&.active {
										color: $colorDarkBg;
									}
								}
							}
						}
					}
				}
			}
			&-rate {
				background: $colorWhite;
				color: rgba($colorDarkBg, 0.5);
				&:after {
					border-color: transparent $colorBgGrey $colorBgGrey transparent;
				}
			}
			&-theme {
				background: $colorWhite;
				&:after {
					border-color: transparent $colorBgGrey $colorBgGrey transparent;
				}
				ul {
					li {
						&:not(:last-child) {
							border-color: rgba($colorLightBg, 0.1);
						}
						a {
							color: $colorDarkBg;
						}
					}
				}
			}
			&-lang {
				&__box {
					background: $colorWhite;
					&:after {
						background: rgba($colorLightBg, 0.2);
						border-color: transparent $colorBgGrey $colorBgGrey transparent;
					}
					&.show {
						border-color: rgba($colorLightBg, 0.2);
					}
					&-list {
						ul {
							li {
								a {
									color: rgba($colorDarkBg, 0.5);
									&:hover {
										color: $colorDarkBg;
									}
								}
							}
						}
					}
				}
			}
			&-socials {
				background: $colorWhite;
				&:after {
					border-color: transparent $colorBgGrey $colorBgGrey transparent;
				}
				ul {
					li {
						&:not(:last-child) {
							border-color: rgba($colorLightBg, 0.1);
						}
						a {
							color: $colorDarkBg;
						}
					}
				}
			}
		}
		.main {
			&-sidebar {
				&::-webkit-scrollbar-track {
					background: rgba($colorLightBg, 0.2);
				}
				&::-webkit-scrollbar-thumb {
					background: $colorLightBg;
				}
			}
			&-content {
				&:before {
					background: $colorLightBg;
					opacity: 0.2;
				}
			}
		}
		.box {
			background: $colorWhite;
			&-label {
				opacity: 1;
			}
			&-table {
				table {
					tr {
						&:not(:last-child) {
							border-color: rgba($colorLightBg, 0.1);
						}
						td, th {
							color: rgba($colorDarkBg, 0.5);
							a {
								color: $colorDarkBg;
								&:hover {
									color: $colorGreen;
								}
							}
						}
					}
				}
			}
			.zap {
				&-form {
					&__field {
						background: $colorBgGrey;
					}
					.form {
						&-select {
							&__box {
								background: $colorBgGrey;
							}
						}
					}
				}
			}
		}
		.vaults {
			&:before {
				opacity: 0;
				transition: opacity 1s ease-out;
			}
			&:after {
				opacity: 1;
				transition: opacity 1s ease-in;
			}
			&-details {
				&__link {
					a {
						color: $colorDarkBg;
						&:hover {
							color: $colorGreen;
						}
					}
				}
			}
			&-box {
				&:hover {
					.vaults-box {
						&__content {
							background: $colorWhite;
						}
					}
				}
				&__content {
					background: $colorWhite;
					border-color: rgba($colorLightBg, 0.1);
					&:before {
						background: rgba($colorLightBg, 0.1);
					}
				}
				&__head {
					&:not(:last-child) {
						border-color: rgba($colorLightBg, 0.1);
					}
				}
				&__title {
					color: rgba($colorDarkBg, 0.5);
					b {
						color: $colorDarkBg;
					}
				}
				&__body {
					&:not(:last-child) {
						border-color: rgba($colorLightBg, 0.1);
					}
				}
				&__group {
					&-box {
						color: rgba($colorDarkBg, 0.5);
						&:not(:last-child) {
							border-color: rgba($colorLightBg, 0.1);
						}
						b, strong {
							color: $colorDarkBg;
						}
					}
				}
				&__details {
					color: $colorDarkBg;
				}
			}
		}
		.heading {
			border-color: rgba($colorLightBg, 0.2);
			&-tvl {
				color: rgba($colorDarkBg, 0.5);
				&:before {
					opacity: 0.2;
				}
				b, strong {
					color: $colorDarkBg;
				}
			}
			&-subtitle {
				color: rgba($colorDarkBg, 0.8);
				&:before {
					opacity: 0.2;
				}
			}
			&-info {
				&:before {
					opacity: 0.2;
				}
			}
		}
		.message {
			&-text {
				color: $colorDarkBg;
			}
		}
		.tabs {
			ul {
				background: $colorWhite;
				li {
					color: rgba($colorDarkBg, 0.5);
					&:hover {
						color: $colorDarkBg;
					}
					&.active {
						background: rgba($colorGreen, 0.2);
						color: $colorGreen;
					}
				}
			}
		}
		.catalog {
			&-inner {
				.catalog-item {
					&__box {
						border-color: rgba($colorLightBg, 0.2);
					}
				}
			}
			&-item {
				background: $colorWhite;
				&__tag {
					.tag {
						&.bg {
							&-default {

							}
							&-yellow {
								color: $colorDarkBg;
								.switch {
									[type="checkbox"] {
										& ~ label {
											&:before {
												border-color: $colorDarkBg;
											}
										}
									}
								}
								.icon {
									color: $colorDarkBg;
								}
							}
						}
						b, strong {
							color: $colorDarkBg;
						}
						&.tag-switch{
							&:hover {
								color: $colorDarkBg;
							}
						}
						&.inactive {
							background: rgba($colorDarkBg, 0.1);
							color: rgba($colorDarkBg, 0.5);
							.icon {
								color: rgba($colorDarkBg, 0.5);
							}
							.switch {
								[type="checkbox"] {
									& ~ label {
										color: rgba($colorDarkBg, 0.5);
										&:before {
											border-color: rgba($colorDarkBg, 0.5);
										}
									}
								}
							}
						}
					}
				}
				&__box {
					&-title {
						b, strong {
							color: $colorDarkBg;
						}
					}
					&-value,
					&-balance,
					&-deposited,
					&-apy,
					&-aprd,
					&-tvl {
						border-color: rgba($colorLightBg, 0.2);
					}
					.item {
						&-title {
							color: $colorDarkBg;
						}
						&-label {
							color: rgba($colorDarkBg, 0.5);
						}
						&-price {
							.oldppice {
								color: rgba($colorDarkBg, 0.5);
							}
						}
					}
				}
				&__content {
					border-color: rgba($colorLightBg, 0.2);
					.catalog {
						&-form {
							&__field {
								input {
									background: $colorBgGrey;
								}
							}
						}
					}
				}
			}
			&-form {
				&__amount {
					.zap {
						&-form {
							&__field {
								background: $colorBgGrey;
							}
						}
					}
				}
				&__balance {
					color: rgba($colorDarkBg, 0.5);
					b, strong {
						color: $colorDarkBg;
					}
				}
			}
			&-filter {
				//background: $colorWhite;
				.form {
					&-select {
						&__toggle {
							//background: $colorBgGrey;
						}
					}
				}
			}
			&-toogleButton {
				&.active {
					&:after {
						background: rgba($colorDarkBg, 0.2);
					}
				}
			}
		}
		.blog {
			&-tabs {
				border-color: rgba($colorLightBg, 0.2);
				ul {
					li {
						color: rgba($colorDarkBg, 0.5);
						&:hover {
							color: $colorDarkBg;
						}
						&.active {
							color: $colorDarkBg;
						}
					}
				}
			}
			&-box {
				&:hover {
					.blog-box {
						&__category,
						&__date {
							color: $colorDarkBg;
						}
						&__title {
							color: $colorGreen;
						}
					}
				}
				&__category,
				&__date {
					color: rgba($colorDarkBg, 0.5);
				}
				&__title {
					color: $colorDarkBg;
				}
			}
			&-article {
				&__back {
					a {
						color: $colorDarkBg;
						&:hover {
							color: $colorGreen;
						}
					}
				}
				&__img {
					&:before {
						opacity: 0;
						transition: opacity 1s ease-out;
					}
					&:after {
						opacity: 1;
						transition: opacity 1s ease-in;
					}
				}
				&__category,
				&__date {
					color: rgba($colorDarkBg, 0.5);
				}
				&__title {
					a {
						color: $colorDarkBg;
						&:hover {
							color: $colorGreen;
						}
					}
				}
				&__text {
					color: rgba($colorDarkBg, 0.5);
					&:before {
						opacity: 0.2;
					}
					h2 {
						color: $colorDarkBg;
					}
					b, strong {
						color: $colorDarkBg;
					}
					blockquote {
						color: $colorDarkBg;
					}
				}
				&__next {
					&:before,
					&:after {
						opacity: 0.2;
					}
				}
				&__other {
					&-heading {
						color: $colorDarkBg;
					}
					&-category,
					&-date {
						color: rgba($colorDarkBg, 0.5);
					}
					&-title {
						a {
							color: $colorDarkBg;
							&:hover {
								color: $colorGreen;
							}
						}
					}
				}
			}
		}
		.pagination {
			border-color: rgba($colorLightBg, 0.2);
			ul {
				li {
					a {
						color: rgba($colorDarkBg, 0.5);
						&.active {
							color: $colorDarkBg;
						}
						&:hover {
							color: $colorGreen;
						}
						&.page {
							&-prev,
							&-next {
								&:hover {
									span {
										background: $colorGreen;
									}
								}
								span {
									background: rgba($colorDarkBg, 0.5);
								}
							}
						}
					}
				}
			}
		}
		.not-found {
			&__img {
				&:before {
					opacity: 0;
					transition: opacity 1s ease-out;
				}
				&:after {
					opacity: 1;
					transition: opacity 1s ease-in;
				}
			}
			&__back {
				a {
					color: $colorDarkBg;
					&:hover {
						color: $colorGreen;
					}
				}
			}
			&__text {
				color: rgba($colorDarkBg, 0.8);
			}
		}
		.catalog {
			&-inner {
				.catalog-item {
					background: transparent;
				}
			}
			&-form {
				&__title {
					b {
						color: $colorDarkBg;
					}
				}
				&__field {
					background: $colorWhite;
				}
			}
			&-toogleButton, &-filter__toggleButton{
				background: $colorWhite;
			}
		}
		.zap {
			&-form {
				&__field {
					background: $colorWhite;
				}
				&__balance {
					color: rgba($colorDarkBg, 0.5);
					b, strong {
						color: $colorDarkBg;
					}
				}
			}
		}
		.foundry {
			&-crypto {
				&__couple {
					&:hover,
					&.active {
						background: $colorBgGrey;
						color: $colorDarkBg;
					}
				}
			}
		}
		.chart {
			&-shadow {
				border-color: $colorBgGrey;
			}
		}
	}
}

@media screen and (max-width: 1439px) {

}

@media screen and (max-width: 1199px) {
	body {
		&.light {
			.main {
				&-sidebar {
					background: $colorBgGrey;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	body {
		&.light {
			.heading {
				&-content {
					&.col-lg {
						&:before {
							background: rgba($colorLightBg, 0.2);
						}
					}
				}
			}
			.vaults {
				background: $colorWhite;
				&-box {
					&__content {
						background: transparent;
					}
				}
			}
			.catalog {
				&-item {
					&__box {
						&-apy,
						&-aprd,
						&-tvl {
							&:before {
								background: rgba($colorDarkBg, 0.2);
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	body {
		&.light {
			.header {
				&-right {
					border-color: rgba($colorLightBg, 0.2);
				}
			}
			.blog {
				&-tabs {
					background: $colorWhite;
				}
			}
			.catalog {
				&-filter {
					background: $colorWhite;
					.switch {
						border-color: rgba($colorDarkBg, 0.2);
					}
					.form {
						&-search {
							&__box {
								background: $colorBgGrey;
							}
						}
						&-select {
							&__box {
								background: $colorBgGrey;
							}
						}
					}
				}
				&-item {
					&__content {
						.catalog {
							&-form {
								&__amount {
									border-color: rgba($colorDarkBg, 0.2);
								}
							}
							&-content {
								&__box {
									border-color: rgba($colorDarkBg, 0.2);
								}
							}
						}
					}
				}
			}
		}
	}
}
