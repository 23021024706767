.select-modal {
	position: fixed;
	display: none;
	top: 50%;
	left: 50%;
	width: 365px;
	transform: translate(-50%, -50%);
	z-index: 101;
	&.show {
		display: block;
	}
	&__heading {
		position: relative;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
	&__search {
		position: relative;
		background: $colorBg;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 0;
			left: 0;
			right: 0;
			height: 2px;
			z-index: 1;
			pointer-events: none;
		}
		input {
			height: 40px;
			font-size: 16px;
			padding: 0 16px;
		}
	}
	&__result {
		position: relative;
		background: $colorBg;
		padding-top: 20px;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 0;
			left: 0;
			right: 0;
			height: 2px;
			z-index: 1;
			pointer-events: none;
		}
	}
	&__list {
		position: relative;
	}
	&__scroll {
		max-height: 400px;
		.mCSB {
			&_inside {
				& > .mCSB_container {
					margin-right: 28px;
				}
			}
			&_scrollTools {
				right: 12px;
			}
		}
	}
		&__box {
			position: relative;
			display: flex;
			align-items: center;
			padding: 20px 0 20px 28px;
			transition: background 0.3s ease;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				background: $colorWhite;
				top: 0;
				left: 0;
				right: 0;
				height: 100%;
				opacity: 0;
				transition: opacity 0.3s ease;
			}
			&:hover {
				&:before {
					opacity: 0.1;
				}
			}
			&-img {
				position: relative;
				width: 40px;
				margin-right: 15px;
				img {
					width: 100%;
				}
			}
			&-title {
				position: relative;
				color: $colorGrey;
				font-size: 14px;
				line-height: 1.3;
				b, strong {
					display: block;
					color: $colorWhite;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
		&__button {
			position: relative;
			padding: 24px 0;
			text-align: center;
			&:before {
				content: '';
				position: absolute;
				background: $colorLightBg;
				top: 0;
				left: 28px;
				right: 28px;
				height: 1px;
			}
			.b-left {
				position: relative;
				display: inline-flex;
				align-items: center;
				color: $colorWhite;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.31em;
				text-transform: uppercase;
				&:hover {
					color: $colorGreen;
					.trapeze {
						width: 40px;
					}
				}
			}
		}
	&__empty {
		position: relative;
		display: none;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		padding: 0 16px 20px;
		opacity: 0.5;
		&.show {
			display: block;
		}
	}
	&__close {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 1px;
		right: 0;
		height: 20px;
		width: 12px;
		color: $colorGreen;
		font-size: 8px;
		line-height: 1;
		cursor: pointer;
	}
}
