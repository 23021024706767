@keyframes changeWidth {
	0% {
		width: 100%;
	}
	20% {
		width: 85%;
	}
	40% {
		width: 100%;
	}
}
.sidebar-animate {
	&:hover {
		.sidebar {
			&-menu {
				& > ul {
					& > li {
						animation-play-state: paused;
					}
				}
			}
			&-rate {
				animation-play-state: paused;
			}
		}
	}
	.sidebar {
		&-menu {
			& > ul {
				& > li {
					animation: changeWidth 3s ease-in-out infinite;
				}
				@for $i from 1 through 5 {
					& > li:nth-child(#{$i}) {
						animation-delay: (#{$i * 0.2}s);
					}
				}
			}
		}
		&-rate {
			animation: changeWidth 3s ease-in-out infinite;
			animation-delay: 1.2s;
		}
	}
}
.sidebar {
	position: relative;
	width: 220px;
	padding-top: 4px;
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	&-menu {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		& > ul {
			& > li {
				position: relative;
				background: $colorBg;
				border: 1px solid transparent;
				transition: border-color 1s ease-in-out, background 1s ease-in-out;
				clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
				&.show {
					border-color: $colorLightBg;
					&:after {
						opacity: 1;
					}
					.sidebar-menu__toggle {
						transform: rotateX(180deg);
					}
				}
				&:after {
					content: '';
					position: absolute;
					background: $colorLightBg;
					right: -3px;
					bottom: 6px;
					width: 20px;
					height: 1px;
					transform: rotate(-45deg);
					transition: opacity 0.3s ease, background 1s ease-in-out;
					opacity: 0;
				}
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				& > a {
					position: relative;
					display: flex;
					align-items: center;
					height: 40px;
					color: rgba($colorWhite, 0.5);
					font-size: 16px;
					line-height: 1.3;
					padding: 0 10px;
					transition: color 1s ease-in-out;
					&:hover {
						color: $colorWhite;
						transition: color 0.3s ease;
					}
					&.active {
						color: $colorWhite;
						font-weight: 600;
						pointer-events: none;
						&:before {
							content: '';
							position: absolute;
							background: $colorGreen;
							top: 0;
							left: 0;
							height: 100%;
							width: 1px;
						}
					}
				}
				ul {
					display: none;
					li {
						position: relative;
						padding: 0 10px;
						&:before {
							content: '';
							position: absolute;
							background: $colorGreen;
							top: 50%;
							left: 20px;
							width: 3px;
							height: 3px;
							transform: translateY(-50%);
						}
						a {
							position: relative;
							display: flex;
							align-items: center;
							height: 40px;
							color: rgba($colorWhite, 0.5);
							font-size: 16px;
							line-height: 1.3;
							border-top: 1px solid $colorLightBg;
							padding-left: 30px;
							transition: color 1s ease-in-out, border-color 1s ease-in-out;
							&:hover {
								color: $colorWhite;
								transition: color 0.3s ease
							}
							&.active {
								color: $colorWhite;
								font-weight: 600;
								&:before {
									content: '';
									position: absolute;
									background: $colorGreen;
									top: 0;
									left: -10px;
									height: 100%;
									width: 1px;
								}
							}
						}
					}
				}
			}
		}
		&__icon {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			color: $colorGreen;
			font-size: 20px;
			&:not(:last-child) {
				margin-right: 10px;
			}
			img {
				max-height: 100%;
			}
		}
		&__title {
			position: relative;
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		&__toggle {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			color: $colorGreen;
			font-size: 6px;
			&:not(:first-child) {
				margin-left: auto;
			}
			img {
				max-width: 11px;
			}
		}
	}
	&-rate {
		position: relative;
		display: flex;
		align-items: center;
		background: $colorBg;
		height: 40px;
		color: rgba($colorWhite, 0.5);
		font-size: 16px;
		line-height: 1.3;
		padding: 0 10px;
		transition: background 1s ease-in-out, color 1s ease-in-out;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		&__icon {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			color: $colorGreen;
			font-size: 20px;
			&:not(:last-child) {
				margin-right: 10px;
			}
			img {
				max-height: 100%;
			}
		}
		&__title {
			position: relative;
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
	&-settings {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
		&-theme {
			position: relative;
			background: $colorBg;
			width: 90px;
			transition: background 1s ease-in-out;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
			ul {
				display: flex;
				align-items: center;
				height: 40px;
				li {
					position: relative;
					flex: 1 0 0;
					&:not(:last-child) {
						border-right: 1px solid $colorLightBg;
						transition: border-color 1s ease-in-out;
					}
					a {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						color: $colorWhite;
						font-size: 20px;
						margin: 0 auto;
						transition: color 1s ease-in-out;
						&:hover {
							transition: color 0.3s ease;
						}
						&.active {
							//color: $colorGreen;
							pointer-events: none;
						}
						&[data-theme="dark"] {
							color: $colorGreen !important;
						}
					}
				}
			}
		}
		&-lang {
			position: relative;
			width: 90px;
			margin-left: auto;
			&__box {
				position: relative;
				background: $colorBg;
				z-index: 1;
				border: 1px solid transparent;
				transition: border-color 1s ease-in-out, background 1s ease-in-out;
				clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
				&:after {
					content: '';
					position: absolute;
					background: $colorLightBg;
					right: -3px;
					bottom: 6px;
					width: 20px;
					height: 1px;
					transform: rotate(-45deg);
					transition: opacity 0.3s ease, background 1s ease-in-out;
					opacity: 0;
				}
				&.show {
					border-color: $colorLightBg;
					&:after {
						opacity: 1;
					}
					.sidebar-lang__box {
						&-toggle {
							transform: rotateX(180deg);
						}
					}
				}
				&-head {
					position: relative;
					display: flex;
					align-items: center;
					height: 40px;
					padding: 0 10px;
					cursor: pointer;
				}
				&-icon {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20px;
					height: 20px;
					color: $colorGreen;
					font-size: 20px;
					&:not(:last-child) {
						margin-right: 5px;
					}
					img {
						max-height: 100%;
					}
				}
				&-title {
					position: relative;
					font-size: 16px;
					line-height: 1.3;
					&:not(:last-child) {
						margin-right: 5px;
					}
				}
				&-toggle {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20px;
					height: 20px;
					color: $colorGreen;
					font-size: 6px;
					img {
						max-height: 100%;
					}
				}
				&-list {
					position: relative;
					display: none;
					ul {
						li {
							position: relative;
							a {
								position: relative;
								display: flex;
								align-items: center;
								height: 40px;
								color: rgba($colorWhite, 0.5);
								font-size: 16px;
								padding-left: 35px;
								transition: color 1s ease-in-out;
								&:hover {
									color: $colorWhite;
									transition: color 0.3s ease;
								}
							}
						}
					}
				}
			}
		}
	&-socials {
		position: relative;
		background: $colorBg;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.3;
		transition: background 1s ease-in-out;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		ul {
			position: relative;
			display: flex;
			align-items: center;
			height: 40px;
			li {
				position: relative;
				flex: 1 0 0;
				&:not(:last-child) {
					border-right: 1px solid $colorLightBg;
					transition: border-color 1s ease-in-out;
				}
				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 40px;
					height: 40px;
					color: $colorWhite;
					margin: 0 auto;
					transition: opacity 0.3s ease, color 1s ease-in-out;
					opacity: 0.5;
					&:hover {
						opacity: 1;
					}
					img {
						max-height: 100%;
					}
				}
			}
		}
	}
}
