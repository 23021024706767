.message {
	position: absolute;
	display: flex;
	background: rgba($colorWhite, 0.2);
	top: 25px;
	right: 44px;
	bottom: 25px;
	width: 534px;
	color: $colorWhite;
	border: 1px solid $colorWhite;
	padding: 16px;
	transition: all 0.3s ease;
	&.hide {
		visibility: hidden;
		opacity: 0;
	}
	&-icon {
		position: relative;
		font-size: 16px;
		margin-right: 14px;
	}
	&-text {
		position: relative;
		flex: 1 0 0;
		max-width: 260px;
		color: $colorWhite;
		font-size: 14px;
		line-height: 1.3;
		margin-right: auto;
		transition: color 1s ease-in-out;
	}
	&-close {
		position: relative;
		font-size: 9px;
		margin-left: 14px;
		cursor: pointer;
	}
	&-success {
		background: rgba($colorGreen, 0.2);
		color: $colorGreen;
		border-color: $colorGreen;
	}
	&-warning {
		background: rgba($colorOrange, 0.2);
		color: $colorOrange;
		border-color: $colorOrange;
	}
	&-danger {
		background: rgba($colorPink, 0.2);
		color: $colorPink;
		border-color: $colorPink;
	}
}
