.landing {
	position: relative;
	background-image: url('../img/dots-pattern.svg');
	&:before {
		content: '';
		position: absolute;
		background: linear-gradient(to bottom, rgba($colorDarkBg, 1) 0%, rgba($colorDarkBg, 0) 100%);
		top: 0;
		left: 0;
		right: 0;
		height: 30vh;
		pointer-events: none;
	}
	.main {
		padding-top: 130px;
	}
	&-box {
		position: relative;
		&__head {
			position: relative;
		}
		&__title {
			position: relative;
			color: $colorWhite;
			font-size: 40px;
			font-weight: 700;
			line-height: 1.3;
			letter-spacing: 0.03em;
			padding-top: 32px;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 0;
				left: 0;
				width: 40px;
				height: 2px;
			}
			&:not(:last-child) {
				margin-bottom: 104px;
			}
			h1 {
				font-weight: 700;
			}
		}
		&__button {
			position: relative;
			display: flex;
			align-items: center;
			.btn {
				width: 190px;
			}
			&-or {
				position: relative;
				color: $colorGreen;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.2em;
				text-transform: uppercase;
				opacity: 0.5;
				margin: 0 28px;
			}
		}
		&__body {
			position: relative;
		}
		&__img {
			position: fixed;
			width: 932px;
			height: 932px;
			text-align: center;
			margin-top: -265px;
			margin-left: -205px;
			z-index: -1;
			pointer-events: none;
			animation: rotate 4s linear infinite;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}
		&__chart {
			position: relative;
			background: $colorBg;
			padding: 36px 32px 24px;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 0;
				left: 32px;
				width: 40px;
				height: 2px;
			}
			&-label{
				position: relative;
				color: $colorWhite;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.2em;
				text-transform: uppercase;
				opacity: 0.5;
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
			&-title {
				position: relative;
				color: $colorGreen;
				font-size: 30px;
				font-weight: 600;
				line-height: 1.3;
				&:not(:last-child) {
					margin-bottom: 36px;
				}
			}
		}
	}
}
