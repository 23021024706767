.heading {
	& ~ .zap {
		margin-top: -8px;
	}
}
.zap {
	position: relative;
	display: grid;
	grid-template-columns: 365px 350px;
	grid-column-gap: 132px;
	padding: 0 36px 24px;
	&-form {
		position: relative;
		&__flex {
			position: relative;
			display: flex;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
			.zap-form{
				&__group {
					flex: 1 0 0;
					&:not(:last-child) {
						margin-bottom: 0;
						margin-right: 16px;
					}
				}
			}
		}
		&__group {
			position: relative;
			&:not(:last-child) {
				margin-bottom: 50px;
			}
		}
		&__heading {
			position: relative;
			display: flex;
			align-items: flex-end;
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}
			&__label {
				position: relative;
				font-size: 12px;
				font-weight: 600;
				line-height: 1.3;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				opacity: 0.5;
			}
			&__balance {
				position: relative;
				color: rgba($colorWhite, 0.5);
				font-size: 12px;
				font-weight: 600;
				line-height: 1.3;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				margin-left: auto;
				transition: color 1s ease-in-out;
				b, strong {
					color: $colorWhite;
					font-size: 16px;
					transition: color 1s ease-in-out;
				}
			}
			&__field {
				position: relative;
				background: $colorBg;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				height: 80px;
				border-left: 2px solid $colorGreen;
				padding: 10px 16px 8px 16px;
				clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
				transition: background 1s ease-in-out;
			}
			&__select {
				position: relative;
				display: flex;
				align-items: center;
				cursor: pointer;
				&-icon {
					position: relative;
					width: 30px;
					margin-right: 10px;
				}
				&-title {
					position: relative;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.3;
					margin-right: 10px;
				}
				.icon {
					color: $colorGreen;
					font-size: 6px;
				}
			}
			&__input {
				position: relative;
				&:not(:last-child) {
					margin-bottom: 6px;
				}
				input {
					height: 30px;
					font-size: 23px;
					font-weight: 600;
					padding: 0;
				}
			}
			&__value {
				position: relative;
				width: 100%;
				margin-top: 12px;
				input {
					height: 20px;
					font-size: 16px;
					font-weight: 600;
					padding: {
						left: 0;
						right: 60px;
					};
				}
			}
			&__rate {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 12px;
				font-weight: 600;
				line-height: 1.3;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				opacity: 0.5;
			}
		&__arr {
			position: relative;
			svg {
				position: absolute;
				top: -32px;
				left: 50%;
				transform: translate(-50%, 0);
				z-index: 20;
			}
		}
		&__button {
			position: relative;
			display: flex;
			justify-content: flex-end;
			.btn {
				width: 176px;
				&:not(:last-child) {
					margin-right: 16px;
				}
				&-border {
					margin-top: 0;
				}
			}
		}
	}
	&-content {
		position: relative;
	}
}
