.btn {
	position: relative;
	display: inline-block;
	background: rgba($colorGreen, 0.1);
	height: 56px;
	color: $colorWhite;
	font-size: 16px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.27em;
	line-height: 56px;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	box-shadow: none;
	padding: 0 32px 0 14px;
	transition: color 1s ease-in-out;
	outline: none;
	cursor: pointer;
	clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 28px), calc(100% - 28px) 100%, 0 100%);
	&-border {
		line-height: 50px;
		padding: 3px;
		margin-top: -3px;
	}
	&-title {
		position: relative;
		display: flex;
		align-items: center;
		height: 50px;
		text-transform: uppercase;
		border: 1px solid $colorGreen;
		padding: 8px 16px;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%);
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			right: -6px;
			bottom: 11px;
			width: 36px;
			height: 1px;
			transform: rotate(-45deg);
		}
		.arr-link {
			margin: {
				top: 6px;
				left: auto;
				//right: 6px;
			};
		}
	}
	&-label {
		position: absolute;
		background: $colorDarkBg;
		top: -1px;
		left: 18px;
		max-width: calc(100% - 36px);
		color: $colorGreen;
		font-size: 8px;
		line-height: 1.125;
		letter-spacing: 0.09em;
		text-overflow: ellipsis;
		text-transform: uppercase;
		padding: 0 4px;
		white-space: nowrap;
		overflow: hidden;
		transition: color 1s ease-in-out, background 1s ease-in-out;
		&:before,
		&:after {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 50%;
			width: 1px;
			height: 6px;
			transform: translateY(-50%);
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 0;
		}
	}
	&:hover,
	&:active {
		color: $colorGreen;
		text-decoration: none;
		outline: none;
		transition: color 0.3s ease;
		.btn {
			&-label {
				color: $colorWhite;
				transition: color 0.3s ease, background 1s ease-in-out;
			}
		}
	}
	&:focus {
		box-shadow: none;
		outline: none;
	}
	&-green {
		background: $colorGreen;
		color: $colorWhite;
		&:hover,
		&:active {

		}
	}
	&-grey {
		background: rgba($colorGrey, 0.1);
		border-color: $colorGrey;
		.btn {
			&-title {
				border-color: $colorGrey;
				&:before {
					background: $colorGrey;
				}
			}
		}
	}
	&-nofill {
		background: transparent !important;
	}
	&-md {
		height: 40px;
		font-size: 12px;
		line-height: 40px;
		letter-spacing: 0.1em;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
		&.btn {
			&-border {
				height: 46px;
				clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 22px), calc(100% - 22px) 100%, 0 100%);
				.btn {
					&-title {
						height: 40px;
						clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
						&:before {
							right: -6px;
							bottom: 9px;
							width: 30px;
						}
					}
				}
			}
			&-nofill {
				height: 40px;
				padding: 0;
				margin: 0;
				.btn {
					&-title {
						&:before {
							right: -4px;
							bottom: 10px;
							width: 30px;
						}
					}
				}
			}
		}
	}
	&-min,
	&-max {
		position: absolute;
		background: rgba($colorGreen, 0.2);
		display: flex;
		align-items: center;
		justify-content: center;
		right: 10px;
		bottom: 10px;
		height: 20px;
		min-width: 50px;
		color: $colorGreen;
		font-size: 12px;
		font-weight: 700;
		line-height: 1.16667;
		letter-spacing: 0.31em;
		text-transform: uppercase;
		border: 0;
		cursor: pointer;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
		transition: color 0.3s ease;
		&:hover {
			color: $colorWhite;
		}
	}
	&.disabled,
	&[disabled] {
		background: rgba($colorGrey, 0.1);
		pointer-events: none;
		cursor: auto;
		.btn {
			&-title {
				border-color: $colorGrey;
				&:before {
					background: $colorGrey;
				}
			}
			&-label {
				color: $colorGrey;
				&:before,
				&:after {
					background: $colorGrey;
				}
			}
		}
	}
}
