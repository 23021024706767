.header {
	position: fixed;
	background: $colorDarkBg;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px 0;
	z-index: 50;
	transition: background 1s ease-in-out;
	&-landing {
		padding: 44px 0 12px;
	}
	&-inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&-left {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 220px;
	}
	&-right {
		position: relative;
		display: inline-flex;
		align-items: center;
	}
	&-tvl {
		position: relative;
		padding-left: 32px;
		&__title {
			position: relative;
			font-size: 12px;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			opacity: 0.5;
		}
		&__count {
			position: relative;
			color: $colorGreen;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.3;
		}
	}
	&-switch {
		position: relative;
		display: inline-flex;
		align-items: center;
		font-size: 12px;
		font-weight: 700;
		font-family: 'Roboto', sans-serif;
		line-height: 1.16667;
		letter-spacing: 0.31em;
		text-transform: uppercase;
		&__title {
			position: relative;
			opacity: 0.5;
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		a {
			position: relative;
			display: flex;
			align-items: center;
			color: $colorWhite;
			transition: color 0.3s ease;
			&:hover {
				color: $colorGreen;
				.trapeze {
					width: 40px;
				}
			}
		}
	}
	&-metamask {
		position: relative;
		display: inline-flex;
		align-items: center;
		&:not(:last-child) {
			margin-right: 32px;
		}
		img {
			position: absolute;
			top: 50%;
			left: 0;
			max-height: 25px;
			margin-left: -5px;
			transform: translate(-100%, -50%);
		}
		a {
			position: relative;
			display: flex;
			flex-direction: column;
			color: $colorWhite;
			font-size: 12px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			letter-spacing: 0.31em;
			text-transform: uppercase;
			transition: color 1s ease-in-out;
			&:hover {
				color: $colorGreen;
				transition: color 0.3s ease;
				.trapeze {
					width: 40px;
				}
			}
			span {
				position: relative;
				display: flex;
				align-items: center;
			}
		}
		&__label {
			position: relative;
			color: $colorGrey;
			font-weight: 400;
			letter-spacing: 0.17em;
			padding-left: 7px;
			margin-bottom: -14px;
			transition: color 1s ease-in-out;
		}
	}
	&-connect {
		position: relative;
		display: inline-flex;
		align-items: center;
		img {
			max-height: 25px;
			margin-right: 5px;
		}
		a {
			position: relative;
			display: flex;
			align-items: center;
			color: $colorWhite;
			font-size: 12px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			letter-spacing: 0.31em;
			text-transform: uppercase;
			transition: color 1s ease-in-out;
			&:hover {
				color: $colorGreen;
				transition: color 0.3s ease;
				.trapeze {
					width: 40px;
				}
			}
		}
	}
	&-account {
		position: relative;
		color: $colorGrey;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.3;
		transition: color 1s ease-in-out;
		&:not(:last-child) {
			margin-right: 32px;
		}
		b {
			color: $colorWhite;
			font-weight: 600;
			transition: color 1s ease-in-out;
		}
	}
	&-menu {
		position: relative;
		ul {
			display: inline-flex;
			align-items: center;
			li {
				position: relative;
				&:not(:last-child) {
					margin-right: 62px;
				}
				a {
					position: relative;
					display: block;
					color: $colorWhite;
					font-size: 12px;
					font-weight: 700;
					font-family: 'Roboto', sans-serif;
					line-height: 1.16667;
					letter-spacing: 0.2em;
					text-transform: uppercase;
					padding-left: 6px;
					transition: color 0.3s ease;
					&:before {
						content: '';
						position: absolute;
						background: $colorGreen;
						top: 50%;
						left: 0;
						width: 2px;
						height: 10px;
						margin-top: -1px;
						transform: translateY(-50%);
					}
					&:hover {
						color: $colorGreen;
					}
				}
			}
		}
	}
	&-lang {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		&.show {
			.header-lang {
				&__arr {
					transform: rotateX(180deg);
				}
			}
		}
		&__head {
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;
			&:hover {
				.header-lang {
					&__title {
						color: $colorGreen;
					}
				}
			}
		}
			&__title {
				position: relative;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.31em;
				text-transform: uppercase;
				padding-left: 6px;
				transition: color 0.3s ease;
				&:before {
					content: '';
					position: absolute;
					background: $colorGreen;
					top: 50%;
					left: 0;
					width: 2px;
					height: 10px;
					transform: translateY(-50%);
					margin-top: -1px;
				}
			}
			&__arr {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 20px;
				height: 16px;
				color: $colorGreen;
				font-size: 6px;
				text-align: center;
			}
		&__list {
			position: absolute;
			display: none;
			top: 100%;
			left: 0;
			right: 0;
			padding-top: 6px;
			ul {
				li {
					a {
						position: relative;
						display: flex;
						align-items: center;
						height: 24px;
						color: rgba($colorWhite, 0.5);
						font-size: 12px;
						font-weight: 700;
						font-family: 'Roboto', sans-serif;
						line-height: 1.16667;
						text-transform: uppercase;
						padding: 0 6px;
						transition: color 0.3s ease;
						&:hover {
							color: $colorWhite;
						}
					}
				}
			}
		}
	}
	&-settings {
		position: relative;
		display: inline-flex;
		justify-content: flex-end;
		.sidebar {
			&-lang {
				height: 40px;
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}
	}
}
.menu-toggle,
.menu-close {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $colorBg;
	width: 45px;
	height: 40px;
	color: $colorGreen;
	font-size: 20px;
	padding-right: 2px;
	cursor: pointer;
	clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
	transition: background 1s ease-in-out;
	&.active {
		.icon {
			&:before {
				content: '\EA03';
			}
		}
	}
}
.logo {
	position: relative;
	.logo {
		&-light,
		&-dark {
			transition: opacity 1s ease-in-out;
		}
	}
	.logo-light {
		opacity: 1;
	}
	.logo-dark {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
}
