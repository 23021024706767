@mixin filter-btn {
	text-transform: uppercase;
	text-align: center;
	background: $colorBg;
	color: $colorGreen;
	font-size: 12px;
	position: relative;
	font-weight: 600;
	padding: 17px;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease, background 1s ease-in-out;
	display: none;
	@media screen and (max-width: 767px){
		display: flex;
	}
	.icon {
		margin-right: 5px;
	}
}
.catalog {
	position: relative;
	padding-top: 18px;
	&-toogleButton {
	  	@include filter-btn();
		&:not(&.active) {
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
		}
		&.active {
			&:after {
				content: '';
				position: absolute;
				background: $colorLightBg;
				left: 16px;
				bottom: 0;
				width: calc(100% - 32px);
				height: 1px;
				transition: background 1s ease-in-out;
			}
		}
	}
	&-filter {
		&__toggleButton{
			@include filter-btn();
			border-top: 1px solid $colorLightBg;
		}
		position: relative;
		&:not(:last-child) {
			margin-bottom: 35px;
		}
		.tabs {
			width: 220px;
		}
	}
	&-list {
		position: relative;
	}
	&-item {
		position: relative;
		background: $colorBg;
		transition: background 1s ease-in-out;
		z-index: 0;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		&__tag {
			position: relative;
			display: flex;
			align-items: flex-start;
			.tag {
				position: relative;
				display: flex;
				align-items: center;
				height: 20px;
				font-size: 8px;
				font-weight: 800;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				border: 1px solid transparent;
				clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
				padding: 4px 8px 4px 5px;
				transition: all 0.3s ease, background 1s ease-in-out;
				cursor: pointer;
				z-index: 1;
				&:not(:last-child) {
					margin-right: 10px;
				}
				&:before {
					content: '';
					position: absolute;
					right: -2px;
					bottom: 3px;
					width: 12px;
					height: 1px;
					transform: rotate(-45deg);
					transition: opacity 0.3s ease;
					opacity: 0;
				}
				&:hover {
					&:before {
						opacity: 1;
					}
				}
				&.bg {
					&-default {
						color: $colorGrey;
						border-left-color: $colorGreen;
						&:not(:last-child) {
							margin-right: 114px;
						}
						&:before {
							background: $colorGreen;
						}
						&:hover {
							border-color: $colorGreen;
						}
						.icon {
							color: $colorGrey;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorGrey;
									&:before {
										border-color: $colorGrey;
									}
								}
							}
						}
						b, strong {
							margin-left: 0;
							&:not(:last-child) {
								margin-right: 5px;
							}
						}
					}
					&-green {
						color: $colorGreen;
						//&:before {
						//	background: $colorGreen;
						//}
						//&:hover {
						//	border-color: $colorGreen;
						//}
						.icon {
							color: $colorGreen;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorGreen;
									&:before {
										border-color: $colorGreen;
									}
								}
							}
						}
					}
					&-yellow {
						color: $colorYellow;
						border-left-color: $colorYellow;
						&:before {
							background: $colorYellow;
						}
						&:hover {
							border-color: $colorYellow;
						}
						.icon {
							color: $colorYellow;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorYellow;
									&:before {
										border-color: $colorYellow;
									}
								}
							}
						}
					}
					&-orange {
						color: $colorOrange;
						border-left-color: $colorOrange;
						&:before {
							background: $colorOrange;
						}
						&:hover {
							border-color: $colorOrange;
						}
						.icon {
							color: $colorOrange;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorOrange;
									&:before {
										border-color: $colorOrange;
									}
								}
							}
						}
					}
					&-violet {
						color: $colorViolet;
						border-left-color: $colorViolet;
						&:before {
							background: $colorViolet;
						}
						&:hover {
							border-color: $colorViolet;
						}
						.icon {
							color: $colorViolet;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorViolet;
									&:before {
										border-color: $colorViolet;
									}
								}
							}
						}
					}
					&-pink {
						color: $colorPink;
						border-left-color: $colorPink;
						&:before {
							background: $colorPink;
						}
						&:hover {
							border-color: $colorPink;
						}
						.icon {
							color: $colorPink;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorPink;
									&:before {
										border-color: $colorPink;
									}
								}
							}
						}
					}
					&-aqua {
						color: $colorAqua;
						border-left-color: $colorAqua;
						&:before {
							background: $colorAqua;
						}
						&:hover {
							border-color: $colorAqua;
						}
						.icon {
							color: $colorAqua;
						}
						.switch {
							[type="checkbox"] {
								& ~ label {
									color: $colorAqua;
									&:before {
										border-color: $colorAqua;
									}
								}
							}
						}
					}
				}
				&.tag-switch {
					border-left: transparent;
					&:hover {
						color: $colorWhite;
					}
				}
				&.inactive {
					background: rgba($colorWhite, 0.2);
					color: rgba($colorWhite, 0.5);
					border-color: transparent;
					&:hover {
						border-color: transparent;
						&:before {
							opacity: 0;
						}
					}
					.icon {
						color: rgba($colorWhite, 0.5);
					}
					.switch {
						[type="checkbox"] {
							& ~ label {
								color: rgba($colorWhite, 0.5);
								&:before {
									border-color: rgba($colorWhite, 0.5);
								}
							}
						}
					}
				}
				&-title {
					position: relative;
					transition: color 1s ease-in-out;
				}
				.icon {
					font-size: 12px;
					letter-spacing: normal;
					margin-right: 6px;
					transition: color 1s ease-in-out;
				}
				b, strong {
					color: $colorWhite;
					margin-left: 6px;
					transition: color 1s ease-in-out;
				}
				.switch {
					margin-right: 2px;
					pointer-events: none;
					[type="checkbox"] {
						& ~ label {
							min-height: 12px;
							line-height: 1;
						}
					}
				}
				&-lg {
					height: 30px;
					font-size: 12px;
					font-weight: 600;
					padding: 4px 16px 4px 10px;
					margin-bottom: -10px;
					&:before {
						width: 13px;
					}
					.icon {
						font-size: 18px;
					}
					.switch {
						margin-right: 5px;
						[type="checkbox"] {
							& ~ label {
								min-height: 13px;
								padding-left: 22px;
								&:before {
									width: 22px;
									height: 13px;
									border-radius: 7px;
								}
								&:after {
									top: 5px;
									left: 12px;
									width: 8px;
									height: 8px;
								}
							}
							&:checked {
								& ~ label {
									&:after {
										left: 2px;
									}
								}
							}
						}
					}
				}
			}
		}
		&__box {
			position: relative;
			display: flex;
			min-height: 80px;
			padding: 15px 12px;
			cursor: pointer;
			&-heading {
				position: relative;
				display: flex;
				align-items: center;
				flex: 1 0 0;
				min-width: 330px;
				padding-right: 45px;
			}
				&-img {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					width: 110px;
					img {
						max-width: 30px;
						max-height: 30px;
						&:not(:last-child) {
							margin-right: 12px;
						}
					}
				}
				&-title {
					position: relative;
					flex: 1 0 0;
					font-size: 12px;
					font-weight: 600;
					line-height: 1.3;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					b, strong {
						display: block;
						color: $colorWhite;
						font-size: 16px;
						letter-spacing: normal;
						margin-bottom: 5px;
					}
				}
			&-value {
				position: relative;
				width: 28.8%;
				border-left: 1px solid $colorLightBg;
				padding-left: 16px;
				transition: border-color 1s ease-in-out;
				&-group {
					position: relative;
					display: flex;
					align-items: flex-end;
					&:not(:last-child) {
						margin-bottom: 16px;
					}
				}
				.item {
					&-label {
						min-width: 80px;
						&:not(:last-child) {
							margin-right: 6px;
						}
					}
				}
			}
			&-balance {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 14.4%;
				border-left: 1px solid $colorLightBg;
				padding-left: 3.92%;
				transition: border-color 1s ease-in-out;
			}
			&-deposited {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 14.4%;
				border-left: 1px solid $colorLightBg;
				padding-left: 3.92%;
				transition: border-color 1s ease-in-out;
			}
			&-tvl {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 12.65%;
				border-left: 1px solid $colorLightBg;
				padding-left: 2.091%;
				transition: border-color 1s ease-in-out;
			}
			&-apy {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 15.27%;
				border-left: 1px solid $colorLightBg;
				padding-left: 3.05%;
				transition: border-color 1s ease-in-out;
			}
			&-aprd {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 15.27%;
				border-left: 1px solid $colorLightBg;
				padding-left: 3.05%;
				transition: border-color 1s ease-in-out;
			}
			.item {
				&-title {
					position: relative;
					color: $colorWhite;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.3;
					text-transform: uppercase;
					margin-bottom: 5px;
					transition: color 1s ease-in-out;
					.price {
						position: absolute;
						top: -14px;
						left: 0;
						font-size: 12px;
						font-weight: 600;
						line-height: 1.3;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						opacity: 0.5;
					}
				}
				&-label {
					position: relative;
					color: rgba($colorWhite, 0.5);
					font-size: 12px;
					font-weight: 600;
					line-height: 1.3;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					transition: color 1s ease-in-out;
				}
				&-price {
					position: relative;
					&.up {
						padding-left: 20px;
						.price {
							color: $colorGreen;
						}
					}
					&.down {
						padding-left: 20px;
						.price {
							color: $colorPink;
						}
					}
					.oldppice {
						position: relative;
						color: rgba($colorWhite, 0.5);
						font-size: 12px;
						font-weight: 600;
						line-height: 1.3;
						letter-spacing: 0.08em;
						text-decoration: line-through;
						transition: color 1s ease-in-out;
					}
					.price {
						position: relative;
						display: flex;
						align-items: center;
						color: $colorWhite;
						font-size: 16px;
						font-weight: 600;
						line-height: 1.3;
						text-transform: uppercase;
						transition: color 1s ease-in-out;
						&-arr {
							position: absolute;
							display: flex;
							align-items: center;
							top: 0;
							left: 0;
							transform: translateX(-100%);
							.icon {
								&:not(:first-child) {
									margin-left: -5px;
								}
							}
						}
						.icon {
							font-size: 20px;
						}
					}
				}
			}
			[class*="-information"] {
				font-size: 18px;
				margin-left: 12px;
			}
			.catalog-item__box-value {
				.item {
					&-title {
						margin-bottom: 0;
					}
				}
			}
		}
		&__content {
			position: relative;
			display: none;
			border-top: 1px solid $colorLightBg;
			padding: 28px;
			transition: border-color 1s ease-in-out;
			.catalog {
				&-form {
					grid-template-columns: repeat(2, 1fr) 1.3fr;
					grid-column-gap: 44px;
					padding: 0;
					&:not(:last-child) {
						margin-bottom: 68px;
					}
					&__group {
						.catalog {
							&-form {
								&__heading {
									display: block;
								}
								&__label {
									margin-right: 0;
									margin-bottom: 2px;
								}
							}
						}
					}
					&__field {
						input {
							background: $colorDarkBg;
							font-weight: 600;
						}
					}
				}
				&-content {
					grid-template-columns: repeat(2, 1fr) 1.294fr;
					grid-column-gap: 44px;
					padding: 0;
				}
			}
		}
	}
	&-inner {
		position: relative;
		.catalog-item {
			background: transparent;
			&__box {
				cursor: auto;
				border-bottom: 1px solid $colorLightBg;
				padding: {
					top: 40px;
					bottom: 40px;
				};
				transition: border-color 1s ease-in-out;
				&-title {
					.item-title {
						font-size: 23px;
					}
				}
			}
		}
	}
	&-form {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr) 0.5fr;
		grid-column-gap: 94px;
		padding: 24px 36px;
		&__flex {
			position: relative;
			display: flex;
		}
		&__group {
			position: relative;
		}
			&__heading {
				position: relative;
				display: flex;
				align-items: flex-end;
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}
				&__label {
					position: relative;
					font-size: 12px;
					font-weight: 600;
					line-height: 1.3;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					opacity: 0.5;
					margin-right: 5px;
				}
				&__title {
					position: relative;
					color: $colorGrey;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.3;
					text-transform: uppercase;
					transition: color 1s ease-in-out;
					b, strong {
						color: $colorWhite;
						font-weight: 600;
						transition: color 1s ease-in-out;
					}
				}
				&__balance {
					position: relative;
					color: rgba($colorWhite, 0.5);
					font-size: 12px;
					font-weight: 600;
					line-height: 1.3;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					margin-left: auto;
					transition: color 1s ease-in-out;
					b, strong {
						color: $colorWhite;
						font-size: 16px;
						transition: color 1s ease-in-out;
					}
				}
		&__field {
			position: relative;
			background: $colorBg;
			border-left: 2px solid $colorGreen;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
			transition: background 1s ease-in-out;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			input {
				height: 80px;
				font-size: 23px;
				padding-left: 16px;
				padding-right: 80px;
			}
		}
		&__range {
			position: relative;
			&:not(:last-child){
				margin-bottom: 24px;
			}
		}
		&__button {
			position: relative;
			display: flex;
			.btn {
				width: calc(50% - 6px);
				&:not(:last-child){
					margin-right: 12px;
				}
			}
		}
		&__link {
			position: relative;
			display: flex;
			flex-direction: column;
			padding-top: 20px;
			.btn {
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 40px;
				}
			}
			a {
				&:not(:last-child) {
					margin-bottom: 40px;
				}
			}
		}
		&__amount {
			position: relative;
			padding: 0 20px;
			&-title {
				position: relative;
				font-size: 12px;
				font-weight: 600;
				line-height: 1.3;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}
			.form {
				&-flex {
					&:not(:last-child) {
						margin-bottom: 30px;
					}
				}
				&-group {
					flex: 1 0 0;
					&:not(:first-child) {
						flex: none;
						width: 125px;
						margin-top: 22px;
					}
				}
			}
			.catalog {
				&-form {
					&__field {
						input {
							font-size: 23px;
						}
					}
				}
			}
			.zap {
				&-form {
					&__field {
						background: $colorDarkBg;
					}
				}
			}
			.btn {
				width: 215px;
			}
		}
	}
	&-content {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr) 0.5fr;
		grid-column-gap: 94px;
		padding: 24px 36px;
		&__box {
			position: relative;
		}
	}
}
.total {
	position: relative;
	padding-top: 4px;
	padding-left: 8px;
	margin-left: 24px;
	&:before {
		content: '';
		position: absolute;
		background: $colorGreen;
		top: 5px;
		left: 0;
		bottom: 5px;
		width: 1px;
	}
	&-line {
		position: relative;
		display: flex;
		align-items: flex-end;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
		.total {
			&-label {
				&:not(:last-child) {
					min-width: 60px;
					margin-bottom: 0;
					margin-right: 8px;
				}
			}
			&-title {
				flex: 1 0 0;
			}
		}
	}
	&-label {
		position: relative;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		opacity: 0.5;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
	&-title {
		position: relative;
		font-size: 23px;
		font-weight: 600;
		line-height: 1;
	}
}
