.modal {
	position: fixed;
	background: rgba($colorDarkBg, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 24px 16px;
	transition: opacity 0.3s ease;
	overflow-y: auto;
	visibility: hidden;
	opacity: 0;
	z-index: 101;
	&.show {
		visibility: visible;
		opacity: 1;
	}
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		border-radius: 4px;
	}
	&::-webkit-scrollbar-track {
		background: $colorLightBg;
		transition: background 1s ease-in-out;
	}
	&::-webkit-scrollbar-thumb {
		background: $colorDarkBg;
		border-radius: 4px;
		transition: background 1s ease-in-out;
	}
	&-box {
		position: relative;
		width: 365px;
		margin: auto;
		&.modal {
			&-lg {
				width: 450px;
			}
		}
	}
	&-head {
		position: relative;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}
	&-body {
		position: relative;
		background: $colorBg;
		border-top: 2px solid $colorGreen;
		padding: 40px 28px;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
	}
	&-scan {
		position: relative;
		text-align: center;
		padding: 20px 0 32px;
		.modal {
			&-text {
				&:not(:last-child) {
					margin-bottom: 32px;
				}
			}
		}
	}
	&-logo {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 40px;
		}
		img {
			max-width: 80px;
		}
	}
	&-text {
		position: relative;
		color: $colorGrey;
		font-size: 14px;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 12px;
		}
		table {
			width: 100%;
			border-collapse: collapse;
			&:first-child {
				margin-top: -30px;
			}
			&:not(:last-child) {
				margin-bottom: 12px;
			}
			thead {
				tr {
					&:last-child {
						border-bottom: 0;
						th, td {
							padding-bottom: 0;
						}
					}
					th, td {
						color: rgba($colorGrey, 0.5);
					}
				}
			}
			tbody {
				tr {
					th, td {

					}
				}
			}
			tr {
				border-bottom: 1px solid $colorLightBg;
				th, td {
					vertical-align: middle;
					height: 32px;
					color: $colorWhite;
					font-size: 12px;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-align: left;
					text-transform: uppercase;
					padding: 15px 8px;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
					.b-left {
						padding-left: 6px;
						&:before {
							width: 1px;
						}
					}
					.price {
						color: $colorWhite;
						font-size: 16px;
					}
				}
			}
		}
		ul {
			li {
				position: relative;
				padding-left: 16px;
				&:before {
					content: '•';
					position: absolute;
					top: 2px;
					left: 0;
					font-size: 12px;
				}
			}
		}
	}
	&-qr {
		position: relative;
		img {
			max-width: 200px;
		}
	}
	&-title {
		position: relative;
		font-size: 23px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 40px;
		}
	}
	&-button {
		position: relative;
		display: flex;
		align-items: flex-start;
		.btn {
			flex: 1 0 0;
			&:not(:last-child) {
				margin-right: 20px;
			}
		}
	}
	&-link {
		position: relative;
		padding: 24px 0;
		text-align: center;
		border-top: 1px solid $colorLightBg;
		&:last-child {
			margin-bottom: -40px;
		}
		.b-left {
			display: inline-flex;
			align-items: center;
			color: $colorWhite;
			font-size: 12px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			letter-spacing: 0.31em;
			text-transform: uppercase;
			&:hover {
				color: $colorGreen;
				.trapeze {
					width: 40px;
				}
			}
		}
	}
	&-close {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 1px;
		right: 0;
		height: 20px;
		width: 12px;
		color: $colorGreen;
		font-size: 8px;
		line-height: 1;
		cursor: pointer;
	}

	.form {
		&-select {
			&__box {
				background: $colorLightBg;
				.mCSB_scrollTools {
					.mCSB_draggerRail {
						background: rgba($colorWhite, 0.1);
					}
				}
			}
		}
	}
	.zap {
		&-form {
			&__field {
				background: $colorLightBg;
			}
		}
	}
}
