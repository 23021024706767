.not-found {
	position: relative;
	display: flex;
	min-height: 260px;
	padding-top: 24px;
	padding-left: 32px;
	&__img {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -310px;
		margin-left: 270px;
		width: 932px;
		height: 932px;
		text-align: center;
		padding-top: 52px;
		z-index: -1;
		pointer-events: none;
		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(to bottom, rgba($colorDarkBg, 1) 0%, rgba($colorDarkBg, 0) 100%);
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			z-index: 1;
			transition: opacity 1s ease-in;
		}
		&:after {
			content: '';
			position: absolute;
			background: linear-gradient(to bottom, rgba($colorBgGrey, 1) 0%, rgba($colorBgGrey, 0) 100%);
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			z-index: 1;
			opacity: 0;
			transition: opacity 1s ease-out;
		}
		&-bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			animation: rotate 4s linear infinite;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}
	}
	&__back {
		position: relative;
		width: 158px;
		&:not(:last-child) {
			margin-right: 24px;
		}
		a {
			position: relative;
			display: inline-flex;
			align-items: center;
			color: $colorWhite;
			font-size: 12px;
			font-weight: 600;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			letter-spacing: 0.31em;
			text-transform: uppercase;
			transition: color 1s ease-in-out;
			&:hover {
				color: $colorGreen;
				transition: color 0.3s ease;
				.trapeze {
					width: 40px;
				}
			}
		}
	}
	&__text {
		position: relative;
		max-width: 150px;
		color: $colorGrey;
		font-size: 14px;
		line-height: 1.3;
		transition: color 1s ease-in-out;
	}
}
