.pagination {
	position: relative;
	border-bottom: 1px solid $colorLightBg;
	margin: 100px 0;
	transition: border-color 1s ease-in-out;
	ul {
		display: flex;
		li {
			position: relative;
			&:not(:last-child) {
				margin-right: 14px;
			}
			a {
				position: relative;
				display: flex;
				align-items: center;
				height: 26px;
				color: $colorGrey;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.1em;
				text-align: center;
				text-transform: uppercase;
				padding: 6px 15px;
				transition: color 1s ease-in-out;
				&.active {
					color: $colorWhite;
					&:before {
						content: '';
						position: absolute;
						background: $colorGreen;
						left: 0;
						right: 0;
						bottom: -1px;
						height: 1px;
					}
				}
				&:hover {
					color: $colorGreen;
					transition: color 0.3s ease;
				}
				&.page {
					&-prev,
					&-next {
						padding: 6px 0;
						&:hover {
							span {
								background: $colorGreen;
								transition: background 0.3s ease;
							}
						}
						span {
							display: block;
							background: $colorGrey;
							width: 32px;
							height: 9px;
							margin-top: -1px;
							transition: background 1s ease-in-out;
						}
					}
					&-prev {
						margin-right: 12px;
						span {
							clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 5px 100%, 0 calc(100% - 5px));
						}
					}
					&-next {
						margin-left: 12px;
						span {
							clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 5px), calc(100% - 5px) 100%, 0 100%);
						}
					}
				}
				&.disabled,
				&[disabled] {
					pointer-events: none;
				}
			}
		}
	}
}
