$colorBlack: #000;
$colorWhite: #FFF;
$colorBg: #202427;
$colorLightBg: #283037;
$colorDarkBg: #16191C;
$colorBgGrey: #F2F5F6;
$colorGrey: #A2A3A4;
$colorLightGrey: #999;
$colorDarkGrey: #333;
$colorYellow: #EEFE39;
$colorOrange: #F08E1B;
$colorViolet: #AB42ED;
$colorPink: #E94680;
$colorAqua: #53CDF4;
$colorGreen: #43B446;
$colorLightGreen: lighten($colorGreen, 10);
$colorDarkGreen: #2D5832;
$colorRed: #CC0000;
$colorLightRed: lighten($colorRed, 10);
$colorDarkRed: darken($colorRed, 10);

html,
body {
	height: 100%;
}
body {
	background: $colorDarkBg;
	color: $colorWhite;
	font-size: 14px;
	font-family: 'SF Pro Text', sans-serif;
	line-height: 1.25;
	overflow-x: hidden;
	overflow-y: auto;
	transition: background 1s ease-in-out, color 1s ease-in-out;
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		border-radius: 4px;
	}
	&::-webkit-scrollbar-track {
		background: $colorLightBg;
		transition: background 1s ease-in-out;
	}
	&::-webkit-scrollbar-thumb {
		background: $colorDarkBg;
		border-radius: 4px;
		transition: background 1s ease-in-out;
	}
}
* {
	outline: none;
	box-sizing: border-box;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}
h1, h2, h3, h4, h5, h6 {
	font-size: 1em;
	line-height: 1;
	margin: 0;
}
img {
	vertical-align: top;
	max-width: 100%;
}
a {
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}
ul,
ol {
	list-style-type: none;
	list-style-position: inside;
	padding: 0;
	margin: 0;
}
p {
	margin: 0;
}
button {
	outline: none;
	&:focus{
		outline: none;
	}
}
[class^='icon-'],
[class*=' icon-'] {
	position: relative;
	vertical-align: middle;
}
.wrapper {
	position: relative;
	min-width: 320px;
	min-height: 100%;
	padding-top: 62px;
}
.container {
	//min-width: 1440px !important;
	width: 100%;
	padding: {
		left: 16px;
		right: 16px;
	};
	margin: {
		left: auto;
		right: auto;
	};
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin: {
		left: -8px;
		right: -8px;
	};
}
@mixin col-default {
	flex: 1 0 0;
}
@mixin col-auto {
	flex: 0 0 auto;
	width: auto;
}
@mixin col($value) {
	$result: calc(#{$value} * 100% / 24);
	flex: 0 0 auto;
	width: $result;
}
.col ,
[class*="col-"] {
	width: 100%;
	max-width: 100%;
	padding: {
		left: 8px;
		right: 8px;
	};
}
.col {
	@include col-default;
	@for $i from 1 through 24 {
		&-#{$i} {
			@include col($i);
		}
	}
	&-auto {
		@include col-auto;
	}
}
.offset {
	@for $i from 1 through 24 {
		&-#{$i} {
			margin-left: calc(#{$i} * 100% / 24);
		}
	}
}
.w {
	&-auto {
		width: auto !important;
	}
	&-100 {
		width: 100% !important;
	}
}
.d {
	&-block {
		display: block !important;
	}
	&-none {
		display: none !important;
	}
	&-inline {
		display: inline !important;
	}
	&-flex {
		display: flex !important;
	}
}
.text {
	&-left {
		text-align: left !important;
	}
	&-center {
		text-align: center !important;
	}
	&-right {
		text-align: right !important;
	}

	&-black {
		color: $colorDarkBg !important;
	}
	&-white {
		color: $colorWhite !important;
	}
	&-orange {
		color: $colorYellow !important;
	}
	&-orange {
		color: $colorOrange !important;
	}
	&-violet {
		color: $colorViolet !important;
	}
	&-pink {
		color: $colorPink !important;
	}
	&-aqua {
		color: $colorAqua !important;
	}
	&-green {
		color: $colorGreen !important;
	}
	&-red {
		color: $colorRed !important;
	}
}
.flex {
	&-row {
		flex-direction: row !important;
		&-reverse {
			flex-direction: row-reverse !important;
		}
	}
	&-column {
		flex-direction: column !important;
		&-reverse {
			flex-direction: column-reverse !important;
		}
	}
}
.align {
	&-item {
		&-start {
			align-items: flex-start !important;
		}
		&-end {
			align-items: flex-end !important;
		}
		&-center {
			align-items: center !important;
		}
	}
	&-self {
		&-start {
			align-self: flex-start !important;
		}
		&-end {
			align-self: flex-end !important;
		}
		&-center {
			align-self: center !important;
		}
	}
}
.justify-content {
	&-start {
		justify-content: flex-start !important;
	}
	&-end {
		justify-content: flex-end !important;
	}
	&-center {
		justify-content: center !important;
	}
	&-between {
		justify-content: space-between !important;
	}
	&-around {
		justify-content: space-around !important;
	}
}
.margin {
	&-left {
		&-auto {
			margin-left: auto !important;
		}
	}
	&-right {
		&-auto {
			margin-left: auto !important;
		}
	}
}
.bg {
	&-default {
		background: $colorLightBg;
	}
	&-green {
		background: rgba($colorGreen, 0.2);
	}
	&-yellow {
		background: rgba($colorYellow, 0.2);
	}
	&-orange {
		background: rgba($colorOrange, 0.2);
	}
	&-violet {
		background: rgba($colorViolet, 0.2);
	}
	&-pink {
		background: rgba($colorPink, 0.2);
	}
	&-aqua {
		background: rgba($colorAqua, 0.2);
	}
}
.b {
	&-left {
		position: relative;
		padding-left: 7px;
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 50%;
			left: 0;
			width: 2px;
			height: 10px;
			margin-top: -1px;
			transform: translateY(-50%);
		}
	}
	&-right {
		padding-right: 7px;
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 50%;
			right: 0;
			width: 2px;
			height: 10px;
			margin-top: -1px;
			transform: translateY(-50%);
		}
	}
}
.trapeze {
	position: relative;
	background: $colorGreen;
	width: 32px;
	height: 9px;
	margin-top: -1px;
	margin-left: 4px;
	pointer-events: none;
	transition: width 0.3s ease;
	clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 5px), calc(100% - 5px) 100%, 0 100%);
	//&:after {
	//	content: '';
	//	position: absolute;
	//	bottom: 0;
	//	right: 0;
	//	width: 0;
	//	height: 0;
	//	border-width: 2.5px;
	//	border-style: solid;
	//	border-color: transparent $colorDarkBg $colorDarkBg transparent;
	//}
	&.invert {
		margin-left: 0;
		margin-right: 4px;
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 5px 100%, 0 calc(100% - 5px));
	}
}
.scroll-content {
	max-height: 100vh;
}
.heading {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 150px;
	padding: 25px 44px 25px 32px;
	border-bottom: 1px solid $colorLightBg;
	margin-bottom: 32px;
	transition: border-color 1s ease-in-out;
	&-left {
		position: relative;
		padding-right: 90px;
	}
	&-title {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 38px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 4px;
		}
		.icon {
			color: $colorGreen;
			font-size: 40px;
			margin-right: 16px;
		}
		h1 {
			font-weight: 600;
		}
	}
	&-subtitle {
		position: relative;
		//max-width: 450px;
		max-width: 520px;
		color: $colorGrey;
		font-size: 16px;
		line-height: 1.3;
		padding-left: 25px;
		transition: color 1s ease-in-out;
		&:before {
			content: '';
			position: absolute;
			background: $colorLightBg;
			top: 50%;
			left: 0;
			width: 1px;
			height: calc(100% - 6px);
			min-height: 28px;
			margin-top: -2px;
			transform: translateY(-50%);
			transition: opacity 1s ease-in-out;
		}
	}
	&-tvl {
		position: relative;
		color: rgba($colorWhite, 0.5);
		font-size: 12px;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		transition: color 1s ease-in-out;
		b, strong {
			display: block;
			color: $colorWhite;
			font-size: 16px;
			font-weight: 600;
			transition: color 1s ease-in-out;
		}
	}
	&-content {
		position: relative;
		display: flex;
		padding: {
			top: 6px;
			left: 0;
			right: 0;
		};
	}
	&-info {
		position: relative;
		align-self: flex-start;
		min-width: 204px;
		padding-left: 9px;
		&:not(:last-child) {
			margin-right: 32px;
		}
		&:before {
			content: '';
			position: absolute;
			background: $colorLightBg;
			top: 50%;
			left: 0;
			width: 1px;
			height: calc(100% - 6px);
			min-height: 28px;
			margin-top: -2px;
			transform: translateY(-50%);
			transition: opacity 1s ease-in-out;
		}
		&__label {
			position: relative;
			font-size: 12px;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			opacity: 0.5;
			&:not(:last-child) {
				margin-bottom: 6px;
			}
		}
		&__title {
			position: relative;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.1;
		}
		&__table {
			position: relative;
			&:last-child {
				margin-bottom: -6px;
			}
 			table {
				tr {
					td, th {
						vertical-align: bottom;
						font-size: 16px;
						font-weight: 600;
						line-height: 1;
						padding: 6px 3px;
						&:first-child {
							font-size: 12px;
							line-height: 1.3;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							padding-left: 0;
							opacity: 0.5;
						}
						&:last-child {
							padding-right: 0;
						}
					}
				}
			}
		}
	}
}
.tabs {
	position: relative;
	ul {
		position: relative;
		display: flex;
		background: $colorBg;
		transition: background 1s ease-in-out;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%);
		li {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 0 0;
			height: 30px;
			color: rgba($colorWhite, 0.5);
			font-size: 12px;
			font-weight: 600;
			letter-spacing: 0.08em;
			text-align: center;
			text-transform: uppercase;
			border: 1px solid transparent;
			padding: 6px;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%);
			cursor: pointer;
			transition: border-color 0.3s ease, color 1s ease-in-out, background 1s ease-in-out;
			&:after {
				content: '';
				position: absolute;
				background: $colorGreen;
				right: -2px;
				bottom: 5px;
				width: 16px;
				height: 1px;
				transform: rotate(-45deg);
				transition: opacity 0.3s ease;
				opacity: 0;
			}
			&.active {
				background: rgba($colorGreen, 0.2);
				color: $colorGreen;
				transition: border-color 0.3s ease, color 0.3s ease-in-out, background 0.3s ease-in-out;
				&:hover {
					@media screen and (hover: hover){
						border-color: $colorGreen;
						&:after {
							opacity: 1;
						}
					}
				}
			}
			&:hover {
				color: $colorWhite;
				transition: border-color 0.3s ease, color 0.3s ease-in-out, background 0.3s ease-in-out;
			}
			&.disable,
			&[disable] {
				background: rgba($colorGrey, 0.2);
				color: $colorGrey;
				border-color: transparent !important;
			}
		}
	}
}
.cursor {
	position: fixed;
	display: none;
	top: 0;
	left: 50%;
	transform: translate(0, 100%);
	z-index: 20;
	svg {
		width: 12px;
		height: 22px;
	}
}
@keyframes toggleOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes rotate {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
.mCSB {
	&_inside {
		& > .mCSB_container {
			margin-right: 9px;
		}
	}
	&_scrollTools {
		width: 9px;
		opacity: 1;
		.mCSB_dragger {
			height: 32px;
			&.mCSB_dragger_onDrag,
			&:hover {
				.mCSB_dragger_bar {
					background: $colorGreen;
					transition: background 1s ease-in-out;
				}
			}
			.mCSB_dragger_bar {
				background: $colorGreen !important;
				width: 9px;
				height: 32px;
				border-radius: 0;
				clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%);
				transition: background 1s ease-in-out;
			}
		}
		.mCSB_draggerRail {
			background: $colorLightBg;
			width: 1px;
			border-radius: 0;
			transition: background 1s ease-in-out;
		}
	}
	&_container {
		&.mCS_no_scrollbar_y {
			&.mCS_y_hidden {
				margin-right: 0 !important;
			}
		}
	}
}
.link {
	position: relative;
	display: inline-flex;
	align-items: center;
	color: $colorWhite;
	font-size: 12px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
	line-height: 1.16667;
	letter-spacing: 0.31em;
	text-transform: uppercase;
	transition: color 1s ease-in-out;
	&:hover,
	&:active {
		color: $colorGreen;
		transition: color 0.3s ease;
	}
	.icon {
		color: $colorGreen;
		font-size: 18px;
		margin-right: 5px;
	}
}
.table {
	position: relative;
	&:not(:last-child) {
		margin-bottom: 24px;
	}
	&-heading {
		position: relative;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		overflow: hidden;
		&:not(:last-child) {
			margin-bottom: 4px;
		}
		span {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				background: $colorLightBg;
				left: calc(100% + 6px);
				bottom: 2px;
				height: 1px;
				width: 100vw;
				transition: opacity 1s ease-in-out;
			}
		}
	}
	&-info {
		position: relative;
		color: $colorGrey;
		font-size: 14px;
		line-height: 1.3;
		transition: color 1s ease-in-out;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
	&-head {
		position: relative;
		.table {
			&-line {
				padding: 8px 0;
				&:not(:last-child) {
					border-bottom: 0;
				}
			}
		}
	}
	&-body {
		position: relative;
	}
	&-line {
		position: relative;
		display: flex;
		align-items: center;
		padding: 16px 0;
		&:not(:last-child) {
			border-bottom: 1px solid $colorLightBg;
			transition: border-color 1s ease-in-out;
		}
		.info {
			margin-left: 6px;
		}
	}
		&-label{
			position: relative;
			flex: 1 0 0;
			color: rgba($colorWhite, 0.5);
			font-size: 12px;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			padding-left: 6px;
			transition: color 1s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 2px;
				bottom: 5px;
				left: 0;
				width: 1px;
			}
		}
		&-title {
			position: relative;
			font-size: 16px;
			font-weight: 600;
			text-align: right;
			line-height: 1.3;
		}
}
.range-slider {
	position: relative;
	padding-top: 18px;
	padding-bottom: 3px;
	&__line {
		position: relative;
		background: $colorGrey;
		height: 2px;
		transition: background 1s ease-in-out;
		&:before,
		&:after {
			content: '';
			position: absolute;
			background: $colorGrey;
			top: 50%;
			width: 2px;
			height: 8px;
			transform: translateY(-50%);
			transition: background 1s ease-in-out;
		}
		&:before {
			background: $colorGreen;
			left: 0;
		}
		&:after {
			right: 0;
		}
	}
	&__progress {
		position: absolute;
		background: $colorGreen;
		bottom: 3px;
		left: 0;
		width: 50%;
		height: 2px;
	}
	&__button {
		position: absolute;
		background: $colorGreen;
		left: calc(50% - 16px);
		bottom: 0;
		width: 32px;
		height: 8px;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 4px), calc(100% - 4px) 100%, 0 100%);
		cursor: pointer;
	}
	&__count {
		position: absolute;
		bottom: 10px;
		left: calc(50% - 16px);
		color: $colorGreen;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.3;
	}
}
.info {
	position: relative;
	top: -1px;
	cursor: pointer;
	&:hover {
		.info {
			&-text {
				opacity: 1;
			}
		}
	}
	&-text {
		position: absolute;
		background: rgba($colorGreen, 0.2);
		left: calc(100% + 12px);
		bottom: calc(100% - 12px);
		min-width: 190px;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.3;
		text-align: left;
		border: 1px solid $colorGreen;
		padding: 12px 16px 12px 46px;
		clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 0% 100%, 10px calc(100% - 8px));
		pointer-events: none;
		transition: opacity 0.3s ease;
		opacity: 0;
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: 0;
			left: 9px;
			width: 1px;
			height: calc(100% - 7px);
		}
		&:after {
			content: '';
			position: absolute;
			background: $colorGreen;
			left: -3px;
			bottom: 2px;
			width: 14px;
			height: 1px;
			transform: rotate(-40deg);
		}
		.icon {
			position: absolute;
			top: 12px;
			left: 20px;
		}
	}
	.icon {
		color: $colorGreen;
		font-size: 18px;
	}
}
.crypto-couple {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 10px;
	img {
		width: 30px;
		height: 30px;
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.crypto-title {
	position: relative;
	flex: 1 0 0;
}
.overflow {
	position: fixed;
	background: $colorDarkBg;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease;
	z-index: 100;
	&.show {
		opacity: 0.9;
		visibility: visible;
	}
	&.menu-show {
		opacity: 0.5;
		visibility: visible;
	}
}
.version {
	position: relative;
	width: 220px;
	font-size: 14px;
	opacity: 0.5;
}
.arr-download {
	width: 21px;
	height: 36px;
	pointer-events: none;
	#arr-1 {
		animation: toggleOpacity 0.6s linear infinite;
		animation-delay: 0.3s;
		opacity: 0;
	}
	#arr-2 {
		animation: toggleOpacity 0.6s linear infinite;
		animation-delay: 0.2s;
		opacity: 0;
	}
	#arr-3 {
		animation: toggleOpacity 0.6s linear infinite;
		animation-delay: 0.1s;
		opacity: 0;
	}
}
.arr-link {
	width: 37px;
	height: 12px;
	pointer-events: none;
	#arr-1 {
		animation: toggleOpacity 0.6s linear infinite;
		animation-delay: 0.3s;
		opacity: 0;
	}
	#arr-2 {
		animation: toggleOpacity 0.6s linear infinite;
		animation-delay: 0.2s;
		opacity: 0;
	}
	#arr-3 {
		animation: toggleOpacity 0.6s linear infinite;
		animation-delay: 0.1s;
		opacity: 0;
	}
}
.field {
	&-lg {
		height: 60px !important;
	}
	&-md {
		height: 50px !important;
	}
	&-sm {
		height: 40px !important;
	}
}
[class*="modal-lightbox"] {
	z-index: 20;
}
