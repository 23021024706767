
@mixin catalog-content-1199 {
	&-form {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 80px;
		grid-row-gap: 35px;
		align-self: center;
		padding: 24px 0px;
		&__amount {
			width: 110%;
			padding: 0;
		}
		&:not(:last-child) {
			margin-bottom: 53px;
		}
		&__heading {
			display: block;
		}
	}
	&-content {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 77px;
		&__box {
			&:first-child {
				grid-area: 1/1/3/2;
			}
			&:last-child {
				margin-top: auto;
			}
		}
	}
}

@mixin catalog-content-767 {
	&-form {
		grid-template-columns: repeat(1, 1fr);
		&__amount {
			border-top: 1px solid $colorLightBg;
			width: 100%;
			padding-top: 30px;
			transition: border-color 1s ease-in-out;
		}
	}
	&-content {
		grid-template-columns: repeat(1, 1fr);
		padding: 0px;
		&__box {
			border-top: 1px solid $colorLightBg;
			padding-top: 30px;
			transition: border-color 1s ease-in-out;
			&:not(:first-child) {
				margin-top: 30px;
			}
			&:first-child {
				grid-area: initial;
			}
		}
	}
}

@media (min-width: 576px) {
	.container {
		//max-width: 540px;
	}
	.col-sm {
		@include col-default;
		@for $i from 1 through 24 {
			&-#{$i} {
				@include col($i);
			}
		}
		&-auto {
			@include col-auto;
		}
	}
	.w {
		&-sm {
			&-auto {
				width: auto !important;
			}
			&-100 {
				width: 100% !important;
			}
		}
	}
	.d {
		&-sm {
			&-block {
				display: block !important;
			}
			&-none {
				display: none !important;
			}
			&-inline {
				display: inline !important;
			}
			&-flex {
				display: flex !important;
			}
		}
	}
	.text {
		&-sm {
			&-left {
				text-align: left !important;
			}
			&-center {
				text-align: center !important;
			}
			&-right {
				text-align: right !important;
			}
		}
	}
	.flex {
		&-sm {
			&-row {
				flex-direction: row !important;
				&-reverse {
					flex-direction: row-reverse !important;
				}
			}
			&-column {
				flex-direction: column !important;
				&-reverse {
					flex-direction: column-reverse !important;
				}
			}
		}
	}
	.align {
		&-item {
			&-sm {
				&-start {
					align-items: flex-start !important;
				}
				&-end {
					align-items: flex-end !important;
				}
				&-center {
					align-items: center !important;
				}
			}
		}
		&-self {
			&-sm {
				&-start {
					align-self: flex-start !important;
				}
				&-end {
					align-self: flex-end !important;
				}
				&-center {
					align-self: center !important;
				}
			}
		}
	}
	.justify-content {
		&-sm {
			&-start {
				justify-content: flex-start !important;
			}
			&-end {
				justify-content: flex-end !important;
			}
			&-center {
				justify-content: center !important;
			}
			&-between {
				justify-content: space-between !important;
			}
			&-around {
				justify-content: space-around !important;
			}
		}
	}
	.margin {
		&-left {
			&-sm {
				&-auto {
					margin-left: auto !important;
				}
			}
		}
		&-right {
			&-sm {
				&-auto {
					margin-right: auto !important;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.container {
		//max-width: 720px;
	}
	.col-md {
		@include col-default;
		@for $i from 1 through 24 {
			&-#{$i} {
				@include col($i);
			}
		}
		&-auto {
			@include col-auto;
		}
	}
	.w {
		&-md {
			&-auto {
				width: auto !important;
			}
			&-100 {
				width: 100% !important;
			}
		}
	}
	.d {
		&-md {
			&-block {
				display: block !important;
			}
			&-none {
				display: none !important;
			}
			&-inline {
				display: inline !important;
			}
			&-flex {
				display: flex !important;
			}
		}
	}
	.text {
		&-md {
			&-left {
				text-align: left !important;
			}
			&-center {
				text-align: center !important;
			}
			&-right {
				text-align: right !important;
			}
		}
	}
	.flex {
		&-md {
			&-row {
				flex-direction: row !important;
				&-reverse {
					flex-direction: row-reverse !important;
				}
			}
			&-column {
				flex-direction: column !important;
				&-reverse {
					flex-direction: column-reverse !important;
				}
			}
		}
	}
	.align-item {
		&-md {
			&-start {
				align-items: flex-start !important;
			}
			&-end {
				align-items: flex-end !important;
			}
			&-center {
				align-items: center !important;
			}
		}
	}
	.align-self {
		&-md {
			&-start {
				align-self: flex-start !important;
			}
			&-end {
				align-self: flex-end !important;
			}
			&-center {
				align-self: center !important;
			}
		}
	}
	.justify-content {
		&-md {
			&-start {
				justify-content: flex-start !important;
			}
			&-end {
				justify-content: flex-end !important;
			}
			&-center {
				justify-content: center !important;
			}
			&-between {
				justify-content: space-between !important;
			}
			&-around {
				justify-content: space-around !important;
			}
		}
	}
	.margin {
		&-left {
			&-md {
				&-auto {
					margin-left: auto !important;
				}
			}
		}
		&-right {
			&-md {
				&-auto {
					margin-right: auto !important;
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.container {
		//max-width: 960px;
	}
	.col-lg {
		@include col-default;
		@for $i from 1 through 24 {
			&-#{$i} {
				@include col($i);
			}
		}
		&-auto {
			@include col-auto;
		}
	}
	.w {
		&-lg {
			&-auto {
				width: auto !important;
			}
			&-100 {
				width: 100% !important;
			}
		}
	}
	.d {
		&-lg {
			&-block {
				display: block !important;
			}
			&-none {
				display: none !important;
			}
			&-inline {
				display: inline !important;
			}
			&-flex {
				display: flex !important;
			}
		}
	}
	.text {
		&-lg {
			&-left {
				text-align: left !important;
			}
			&-center {
				text-align: center !important;
			}
			&-right {
				text-align: right !important;
			}
		}
	}
	.flex {
		&-lg {
			&-row {
				flex-direction: row !important;
				&-reverse {
					flex-direction: row-reverse !important;
				}
			}
			&-column {
				flex-direction: column !important;
				&-reverse {
					flex-direction: column-reverse !important;
				}
			}
		}
	}
	.align {
		&-item {
			&-lg {
				&-start {
					align-items: flex-start !important;
				}
				&-end {
					align-items: flex-end !important;
				}
				&-center {
					align-items: center !important;
				}
			}
		}
		&-self {
			&-lg {
				&-start {
					align-self: flex-start !important;
				}
				&-end {
					align-self: flex-end !important;
				}
				&-center {
					align-self: center !important;
				}
			}
		}
	}
	.justify-content {
		&-lg {
			&-start {
				justify-content: flex-start !important;
			}
			&-end {
				justify-content: flex-end !important;
			}
			&-center {
				justify-content: center !important;
			}
			&-between {
				justify-content: space-between !important;
			}
			&-around {
				justify-content: space-around !important;
			}
		}
	}
	.margin {
		&-left {
			&-lg {
				&-auto {
					margin-left: auto !important;
				}
			}
		}
		&-right {
			&-lg {
				&-auto {
					margin-right: auto !important;
				}
			}
		}
	}
}

@media (min-width: 1200px) {
	.container {
		//max-width: 1140px;
	}
	.col-xl {
		@include col-default;
		@for $i from 1 through 24 {
			&-#{$i} {
				@include col($i);
			}
		}
		&-auto {
			@include col-auto;
		}
	}
	.w {
		&-xl {
			&-auto {
				width: auto !important;
			}
			&-100 {
				width: 100% !important;
			}
		}
	}
	.d {
		&-xl {
			&-block {
				display: block !important;
			}
			&-none {
				display: none !important;
			}
			&-inline {
				display: inline !important;
			}
			&-flex {
				display: flex !important;
			}
		}
	}
	.text {
		&-xl {
			&-left {
				text-align: left !important;
			}
			&-center {
				text-align: center !important;
			}
			&-right {
				text-align: right !important;
			}
		}
	}
	.flex {
		&-xl {
			&-row {
				flex-direction: row !important;
				&-reverse {
					flex-direction: row-reverse !important;
				}
			}
			&-column {
				flex-direction: column !important;
				&-reverse {
					flex-direction: column-reverse !important;
				}
			}
		}
	}
	.align {
		&-item {
			&-xl {
				&-start {
					align-items: flex-start !important;
				}
				&-end {
					align-items: flex-end !important;
				}
				&-center {
					align-items: center !important;
				}
			}
		}
		&-self {
			&-xl {
				&-start {
					align-self: flex-start !important;
				}
				&-end {
					align-self: flex-end !important;
				}
				&-center {
					align-self: center !important;
				}
			}
		}
	}
	.justify-content {
		&-xl {
			&-start {
				justify-content: flex-start !important;
			}
			&-end {
				justify-content: flex-end !important;
			}
			&-center {
				justify-content: center !important;
			}
			&-between {
				justify-content: space-between !important;
			}
			&-around {
				justify-content: space-around !important;
			}
		}
	}
	.margin {
		&-left {
			&-xl {
				&-auto {
					margin-left: auto !important;
				}
			}
		}
		&-right {
			&-xl {
				&-auto {
					margin-right: auto !important;
				}
			}
		}
	}
}

@media (min-width: 1440px) {
	.container {
		max-width: 1440px;
	}
	.col-xxl {
		@include col-default;
		@for $i from 1 through 24 {
			&-#{$i} {
				@include col($i);
			}
		}
		&-auto {
			@include col-auto;
		}
	}
	.w {
		&-xxl {
			&-auto {
				width: auto !important;
			}
			&-100 {
				width: 100% !important;
			}
		}
	}
	.d {
		&-xxl {
			&-block {
				display: block !important;
			}
			&-none {
				display: none !important;
			}
			&-inline {
				display: inline !important;
			}
			&-flex {
				display: flex !important;
			}
		}
	}
	.text {
		&-xxl {
			&-left {
				text-align: left !important;
			}
			&-center {
				text-align: center !important;
			}
			&-right {
				text-align: right !important;
			}
		}
	}
	.flex {
		&-xxl {
			&-row {
				flex-direction: row !important;
				&-reverse {
					flex-direction: row-reverse !important;
				}
			}
			&-column {
				flex-direction: column !important;
				&-reverse {
					flex-direction: column-reverse !important;
				}
			}
		}
	}
	.align {
		&-item {
			&-xxl {
				&-start {
					align-items: flex-start !important;
				}
				&-end {
					align-items: flex-end !important;
				}
				&-center {
					align-items: center !important;
				}
			}
		}
		&-self {
			&-xxl {
				&-start {
					align-self: flex-start !important;
				}
				&-end {
					align-self: flex-end !important;
				}
				&-center {
					align-self: center !important;
				}
			}
		}
	}
	.justify-content {
		&-xxl {
			&-start {
				justify-content: flex-start !important;
			}
			&-end {
				justify-content: flex-end !important;
			}
			&-center {
				justify-content: center !important;
			}
			&-between {
				justify-content: space-between !important;
			}
			&-around {
				justify-content: space-around !important;
			}
		}
	}
	.margin {
		&-left {
			&-xxl {
				&-auto {
					margin-left: auto !important;
				}
			}
		}
		&-right {
			&-xxl {
				&-auto {
					margin-right: auto !important;
				}
			}
		}
	}
}

@media screen and (max-width: 1439px) {
	.heading {
		&-left {
			padding-right: 48px;
		}
	}
	.box {
		//padding: 32px 24px 16px;
	}
	.vaults {
		[class^="col"] {
			&:nth-child(5) {
				display: none;
			}
		}
	}
	.info {
		.info-text {
			left: auto;
			right: calc(100% + 12px);
			clip-path: polygon(0% 0%, calc(100% - 10px) 0%, calc(100% - 10px) calc(100% - 8px), 100% 100%, 0% 100%);
		}
		&.center{
			.info-text {
				left: 50%;
				transform: translateX(-50%);
				right: auto;
				bottom: calc(100% + 5px);
				//uncomment if nessesary
				&:before {
					display: none;
					top: auto;
					left: 0;
					right: auto;
					bottom: 4px;
					transform: initial;
					height: 1px;
					width: calc(50% - 5px);
				}
				&:after {
					display: none;
					top: auto;
					right: 0;
					left: auto;
					bottom: 4px;
					transform: initial;
					height: 1px;
					width: calc(50% - 5px);
				}
				clip-path: none;
				//clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 5px), calc(50% + 5px) calc(100% - 5px), 50% calc(100% + 0px), calc(50% - 5px) calc(100% - 5px), 100% calc(100% - 5px), 0% calc(100% - 5px));
			}
		}
		&.right{
			.info-text {
				&:before {
					left: 9px;
					right: auto;
				}
				&:after {
					right: auto;
					left: -3px;
					transform: rotate(-40deg);
				}
				left: calc(100% + 12px);
				right: auto;
				clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 0% 100%, 10px calc(100% - 10px));
			}
		}
		&-text {
			padding: {
				left: 36px;
				right: 26px;
			};
			&:before {
				left: auto;
				right: 9px;
			}
			&:after {
				left: auto;
				right: -3px;
				transform: rotate(40deg);
			}
			.icon {
				left: 10px;
			}
		}
	}
	.blog {
		&-list {
			grid-column-gap: 32px;
			grid-row-gap: 50px;
		}
		&-article {
			padding: {
				left: 16px;
				right: 16px;
			};
			&__head {
				&:not(:last-child) {
					margin-bottom: 64px;
				}
			}
			&__content {
				width: 514px;
			}
			&__next {
				&:before,
				&:after {
					left: -16px;
				}
			}
		}
	}
	.message {
		width: 398px;
		right: 0px;
	}
	.catalog {
		&-filter {
			.col-md-24 {
				order: 1;
				margin-top: 16px;
			}
		}
		&-item {
			&__box{
				padding: {
					left: 16px;
					right: 16px;
				};
				&-heading {
					min-width: 200px;
					padding-right: 0px;

				}
				&-img {
					width: 63px;
					margin-right: 13px;
					img {
						&:not(:last-child) {
							margin-right: 3px;
						}
					}
				}
				&-value {
					padding-left: 14px;
					width: 34.8%;
				}
			}
		}
	}
	.foundry {
		.col-xl-24 {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
		&-line {
			margin-right: 0;
			&__label {
				&:not(:last-child) {
					margin-right: 6px;
				}
			}
		}
		&-chart {
			&__head {
				.foundry {
					&-line {
						&__label {
							&:not(:last-child) {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	.chart {
		&-big {
			width: 232px;
			height: 232px;
		}
		&-small {
			margin-top: -42px;
		}
		&-shadow {
			border-width: 40px;
		}
	}
}

@media screen and (max-width: 1199px) {
	.wrapper {
		padding-top: 72px;
	}
	.header {
		.row {
			height: 52px;
		}
		&-left {
			min-width: 0;
		}
		&-right {
			flex-direction: column;
			align-items: flex-start;
		}
		&-tvl {
			padding-left: 0;
		}
		&-account {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		&-metamask {
			&:not(:last-child) {
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
		&-settings {
			.sidebar {
				&-lang {
					display: none;
				}
			}
		}
		.logo {
			display: none;
		}
	}
	.main {
		&-inner {
			display: block;
		}
		&.sidebar-hide {
			.main-sidebar {
				left: -260px;
				width: 252px;
			}
		}
		&-sidebar {
			position: fixed;
			background: $colorDarkBg;
			top: 0;
			width: 252px;
			padding: 0 16px 16px;
			z-index: 101;
			transition: left 0.3s ease, background 1s ease-in-out;
			&__panel {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px 0;
			}
		}
		&-content {
			width: 100%;
			&__grid {
				padding-top: 16px;
			}
		}
	}
	.box {
		padding: {
			left: 16px;
			right: 16px;
		};
		&:before {
			left: 16px;
		}
	}
	.heading {
		min-height: 120px;
		margin-bottom: 14px;
		padding: {
			left: 16px;
			right: 16px;
		};
		&-left {
			padding-right: 24px;
			min-width: 253px;
		}
		& ~ .zap {
			margin-top: 0;
		}
		&-info {
			&__table {
				table {
					tr {
						td {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	.vaults {
		&-details {
			padding-left: 16px;
			&:before {
				left: 16px;
			}
		}
	}
	.zap {
		padding: {
			top: 16px;
			left: 16px;
			right: 16px;
		};
		&-content {
			padding-top: 24px;
		}
	}
	.blog {
		&-tabs {
			padding-top: 8px;
		}
		&-content {
			padding: 0 16px;
		}
		&-article {
			&__img {
				height: auto;
				padding-top: 56.91%;
			}
			&__head {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
			&__title {
				max-width: 660px;
			}
			&__content {
				width: 546px;
			}
		}
	}
	.pagination {
		padding: 0 16px;
	}
	.catalog {
		&-filter {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
		&-item {
			&__box {
				padding: 12px 16px;
			}
			&__content {
				padding: 50px 143px;
				.catalog {
					@include catalog-content-1199;
				}
			}
		}
		@include catalog-content-1199;
	}
	.total {
		margin-left: 0;
	}
	.foundry {
		.col-xl-24 {
			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
		&-title {
			padding-left: 16px;
		}
		&-chart {
			&__head {
				.foundry {
					&-line {
						&__label {
							&:not(:last-child) {
								margin-bottom: 12px;
							}
						}
					}
				}
			}
		}
	}
	.chart {
		&-shadow {
			border-width: 48px;
		}
		&-big {
			width: 296px;
			height: 296px;
		}
		&-small {
			margin-top: 0;
		}
	}
}


@media screen and (max-width: 991px) {
	.header {
		&-tvl {
			padding-left: 32px;
		}
	}
	.main {
		&-content {
			[class*="col-lg"] {
				&:not(:last-child, .col-md-18) {
					margin-bottom: 16px;
				}
			}
		}
	}
	.vaults {
		background: $colorBg;
		padding: {
			left: 16px;
			right: 16px;
		};
		&:before {
			content: none;
		}
		&-details {
			max-width: none;
			padding: {
				left: 0;
				right: 0;
			};
			&:before {
				left: 0;
			}
			.box-label {
				&:not(:last-child) {
					margin-bottom: 36px;
				}
			}
			&__text {
				margin-bottom: 20px;
			}
			&__link {
				position: relative;
				left: auto;
				bottom: auto;
				width: 100%;
				padding: 0 8px;
				margin-top: 36px;
			}
		}
		&-box {
			&__content {
				background: transparent;
			}
		}
	}
	.heading {
		flex-wrap: wrap;
		padding: {
			top: 16px;
			bottom: 16px;
		};
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		&-content {
			justify-content: space-between;
			&.col-lg {
				padding: {
					top: 16px;
					left: 0;
					right: 0;
				};
				margin-top: 16px;
				&:before {
					content: '';
					position: absolute;
					background: $colorLightBg;
					top: 0;
					left: -16px;
					right: -16px;
					height: 1px;
					transition: background 1s ease-in-out;
				}
				.heading {
					&-info {
						&:first-child {
							padding-left: 0;
							&:before {
								content: none;
							}
						}
					}
				}
			}
		}
		&-info {
			width: calc(50% - 8px);
			&:not(:last-child) {
				margin-right: 0;
			}
			&.absolute {
				position: absolute;
				left: calc(50% + 8px);
				bottom: calc(100% + 16px);
				.heading {
					&-info {
						&__label {
							&:not(:last-child) {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	.zap {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 82px;
		padding-top: 0;
		&-content {
			padding-top: 24px;
		}
	}
	.blog {
		padding-top: 16px;
		&-list {
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 16px;
			grid-row-gap: 44px;
		}
		&-article {
			&__content {
				position: relative;
			}
			&__text {
				position: relative;
				h2 {
					&:not(:first-child) {
						margin-top: 40px;
					}
				}
				blockquote {
					&:not(:first-child) {
						margin-top: 40px;
					}
					&:not(:last-child) {
						margin-bottom: 40px;
					}
				}
			}
			&__other {
				position: relative;
				top: auto;
				right: auto;
				transform: none;
				&:before {
					content: '';
					position: absolute;
					background: $colorLightBg;
					top: -30px;
					left: -16px;
					width: calc(546px + 16px);
					height: 1px;
					transition: opacity 1s ease-in-out;
				}
				&:after {
					content: '';
					position: absolute;
					background: $colorLightBg;
					left: -16px;
					bottom: -40px;
					width: calc(546px + 16px);
					height: 1px;
					transition: opacity 1s ease-in-out;
				}
				&:not(:first-child) {
					margin-top: 60px;
				}
				&:not(:last-child) {
					margin-bottom: 80px;
				}
			}
		}
	}
	.pagination {
		margin: 80px 0;
	}
	.catalog{
		&-item {
			&__content {
				padding: 16px 16px;
			}
			&__box {
				flex-wrap: wrap;
				padding-top: 24px;
				&-heading {
					width: 45%;
					flex: inherit;
				}
				&-value {
					width: 55%;
				}
				&-apy {
					border-left: 0;
				}
				&-apy, &-aprd, &-tvl {
					width: 33.332%;
					margin-top: 34px;
					position: relative;
					&:before {
						content: '';
						background: $colorLightBg;
						width: 100%;
						height: 1px;
						position: absolute;
						left: 0;
						top: -20px;
						transition: background 1s ease-in-out;
					}
				}
			}
			&__tag {
				flex-wrap: wrap;
				.tag {
					white-space: nowrap;
					margin-bottom: 10px;
				}
			}
		}
	}
	.message {
		max-width: calc(100% - 32px);
		position: fixed;
		top: 82px;
		height: 100px;
		right: 16px;
		z-index: 99;
	}
	.foundry {
		&-line {
			&__title {
				width: 100%;
			}
		}
	}
	.chart {
		&-big {
			margin: {
				top: -20px;
				left: 0;
			};
		}
		&-small {
			margin: {
				top: -4px;
			};
		}
	}
}

@media screen and (max-width: 991px) and (min-width: 768px) {
	.catalog {
		&-item {
			&__tag {
				.tag {
					&:nth-last-child(1){
						margin-left: auto;
					}
					&.margin-left-auto {
						order: 1;
						margin-left: initial !important;
						margin-right: initial !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.wrapper {
		padding-top: 155px;
	}
	.header {
		padding-top: 16px;
		.row {
			height: auto;
		}
		.col-24 {
			order: 1;
			margin-top: 16px;
		}
		&-tvl {
			padding-left: 16px;
		}
		&-metamask {
			img {
				display: none;
			}
		}
		&-right {
			justify-content: center;
			width: 100%;
			height: 65px;
			border-top: 1px solid $colorLightBg;
			padding: {
				top: 10px;
				left: 16px;
				right: 16px;
			};
			transition: border-color 1s ease-in-out;
		}
	}
	.box {
		padding-bottom: 28px;
		.heading {
			margin-bottom: 16px;
		}
		.zap {
			&-form {
				&__group {
					&:not(:last-child) {
						margin-bottom: 32px;
					}
				}
				&__arr {
					svg{
						top: -24px;
					}
				}
				&__button {
					justify-content: flex-start;
				}
				.form {
					&-group {
						&:not(:last-child) {
							margin-bottom: 28px;
						}
					}
				}
			}
		}
	}
	.heading {
		&-info {
			min-width: 140px;
			&:first-child {
				padding-left: 0;
				&:before {
					display: none;
				}
			}
			&.absolute {
				bottom: calc(100% + 22px);
			}
			&__label {
				&:not(:last-child){
					margin-bottom: 3px;
				}
			}
			&__table {
				table {
					tr {
						td {
							padding: 3px;
						}
					}
				}
			}
		}
		&-left {
			padding-right: 0;
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}
		&-subtitle {
			padding-left: 0;
			&:before {
				content: none;
			}
		}
		&-content {
			flex: none;
			width: 100%;
			padding-top: 0;
			margin-top: 10px;
		}
	}
	.vaults {
		[class^="col"] {
			margin-bottom: 16px;
			&:nth-child(5) {
				display: block;
			}
		}
		&-details {
			&__link {
				text-align: center;
				margin-top: 16px;
			}
		}
	}
	.zap {
		grid-template-columns: repeat(1, 1fr);
		padding-top: 16px;
		&-form {
			&:not(:last-child) {
				margin-bottom: 52px;
			}
		}
		&-content {
			padding-top: 0;
		}
	}
	.blog {
		padding-top: 0;
		&-tabs {
			background: $colorBg;
			top: -1px;
			border: 0;
			padding: 24px 16px 16px;
			margin-top: -16px;
			transition: background 1s ease-in-out;
			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}
		&-content {
			padding: 0;
		}
		&-list {
			grid-template-columns: repeat(1, 1fr);
		}
		&-article {
			padding-top: 32px;
			&__img {
				left: -16px;
				right: -16px;
				width: auto;
			}
			&__head,
			&__back,
			&__category,
			&__title {
				&:not(:last-child) {
					margin-bottom: 32px;
				}
			}
			&__title {
				font-size: 28px;
			}
			&__text {
				padding-bottom: 30px;
				h2 {
					font-size: 23px;
					&:not(:first-child) {
						margin-top: 32px;
					}
				}
				blockquote {
					&:not(:last-child) {
						margin-bottom: 32px;
					}
				}
			}
			&__other {
				width: auto;
				&:before ,
				&:after {
					width: auto;
					right: -16px;
				}
			}
			&__next {
				.blog-article {
					&__category,
					&__title {
						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.pagination {
		padding: 0;
		ul {
			justify-content: space-between;
			li {
				&:not(:last-child) {
					margin-right: 0;
				}
				a {
					padding: 6px 12px;
				}
			}
		}
	}
	.catalog {
		&-list {
			margin-top: 16px;
		}
		&-filter {
			display: none;
			background: $colorBg;
			transition: background 1s ease-in-out;
			padding: 16px 16px 0 16px;
			margin-bottom: 0;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
			&.active {
				clip-path: initial;
			}
			.row:nth-last-child(2) {
				margin-bottom: 0;
				 & > div{
					margin-bottom: 16px;
				 }
			}
			.col-md-18 {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
			.col-md-24 {
				order: initial;
				margin-top: initial;
			}
			//.col-md-6 {
			//	order: 1;
			//}
			.tabs {
				width: 100%;
				margin-bottom: 20px;
			}
			.switch {
				width: 100%;
				padding: 20px 0;
				border-top: 1px solid $colorLightBg;
				transition: border-color 1s ease-in-out;
			}
			.form {
				&-search {
					&__box {
						background: $colorDarkBg;
					}
				}
				&-multiple {
					flex-direction: column;
					height: auto;
					align-items: flex-start;
					padding: 0;
				}
				&-select{
					&__box {
						background: $colorDarkBg;
					}
					&__line {
						z-index: 2;
					}
				}
			}
		}
		&-item {
			&__box {
				flex-direction: column;
				& > div {
					border-left: 0;
					width: 100%;
					padding-left: 0;
				}
				&-value {
					border:{
						left: 0;
						top: 1px solid $colorLightBg;
					}
					padding-left: 0;
					margin-top: 16px;
					padding-top: 16px;
					&-group {
						flex-direction: column;
						align-items: flex-start;
					}
				}
				.item{
					&-title {
						.price {
							top: -15px;
							left: 100px;
						}
					}
				}
				&-tvl {
					flex-direction: row-reverse;
					justify-content: flex-end;
					align-items: center;
					.item{
						&-label {
							width: 130px;
						}
					}
				}
				&-aprd, &-apy {
					.item{
						&-price {
							padding-left: 0px !important;
							display: grid;
							grid-template: "row1" auto "row2" auto / 130px 1fr
						}
						&-label {
							grid-area: 1/1/3/2;
							align-self: center;

						}
					}
				}
			}
			&__content {
				.catalog {
					@include catalog-content-767
				}
			}
			&__tag {
				.tag {
					&:nth-last-child(1){
						margin-left: initial;
					}
					&.margin-left-auto {
						order: initial;
						margin-left: initial !important;
						margin-right: 10px !important;
					}
				}
			}
		}
		@include catalog-content-767
	}
	.message {
		top: 162px;
	}
	.foundry {
		&-title {
			padding-right: 16px;
		}
		&-chart {
			flex-wrap: wrap;
		}
	}
	.chart {
		&-shadow {
			border-width: 44px;
		}
		&-big {
			width: 274px;
			height: 274px;
		}
	}
}

@media screen and (max-width: 575px) {
	.main {
		&-content {
			.row {
				.col,
				[class*="col-"] {
					min-height: 0;
				}
			}
			.col-sm {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
		}
	}
	.box {
		height: auto;
	}
	.heading {
		&-content {
			&.col-lg {
				.heading {
					&-info {
						&__table {
							table {
								tr {
									td, th {
										display: block;
									}
								}
							}
						}
					}
				}
			}
		}
		&-info {
			&__label {
				white-space: nowrap;
			}
		}
	}
	.foundry {
		&-crypto {
			width: 100%;
			max-width: none;
			column-count: 2;
			column-gap: 24px;
			margin: {
				right: -24px;
				bottom: 32px;
			};
			&__couple {
				display: inline-flex;
				vertical-align: top;
				width: 100%;
				&:last-child {
					margin-bottom: 16px;
				}
			}
		}
		.row {
			&:last-child {
				.col-lg {
					.foundry {
						&-chart {
							&__body {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.chart {
		&-big {
			margin: {
				top: 0;
				left: auto;
				right: auto;
			};
		}
	}
}
