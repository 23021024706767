@keyframes shadowFlash {
	0% {
		filter: none;
	}
	35% {
		filter: drop-shadow(0 10px 10px rgba(67, 180, 70, 0.3));
	}
	60% {
		filter: none;
	}
	75% {
		filter: drop-shadow(0 10px 10px rgba(67, 180, 70, 0.3));
	}
	85% {
		filter: none;
	}
	92% {
		filter: drop-shadow(0 10px 10px rgba(67, 180, 70, 0.3));
	}
	98% {
		filter: none;
	}
	100% {
		filter: drop-shadow(0 10px 10px rgba(67, 180, 70, 0.3));
	}
}
@keyframes borderFlash {
	0% {
		border-color: rgba($colorWhite, 0.2);
	}
	35% {
		border-color: $colorGreen;
	}
	60% {
		border-color: rgba($colorWhite, 0.2);
	}
	75% {
		border-color: $colorGreen;
	}
	85% {
		border-color: rgba($colorWhite, 0.2);
	}
	92% {
		border-color: $colorGreen;
	}
	98% {
		border-color: rgba($colorWhite, 0.2);
	}
	100% {
		border-color: $colorGreen;
	}
}
.vaults {
	position: relative;
	padding: 16px 0 26px;
	transition: background 1s ease-in-out;
	&:before {
		content: '';
		position: absolute;
		background: linear-gradient(to right, rgba($colorBg, 1) 0%, rgba($colorBg, 0) 100%);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		opacity: 1;
		transition: opacity 1s ease-in;
	}
	&:after {
		content: '';
		position: absolute;
		background: linear-gradient(to right, $colorWhite 0%, rgba($colorWhite, 0) 100%);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		opacity: 0;
		transition: opacity 1s ease-out;
	}
	&-details {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		max-width: 220px;
		padding: 20px 10px 0 32px;
		&:before {
			content: '';
			position: absolute;
			background: $colorGreen;
			top: -16px;
			left: 32px;
			width: 40px;
			height: 2px;
		}
		.box-label {
			&:not(:last-child) {
				margin-bottom: 68px;
			}
		}
		&__text {
			position: relative;
			font-size: 14px;
			line-height: 1.3;
			opacity: 0.5;
			margin-bottom: 40px;
		}
		&__link {
			position: absolute;
			left: 32px;
			bottom: 24px;
			a {
				position: relative;
				display: inline-flex;
				align-items: center;
				color: $colorWhite;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.31em;
				text-transform: uppercase;
				transition: color 1s ease-in-out;
				&:hover {
					color: $colorGreen;
					transition: color 0.3s ease;
					.trapeze {
						width: 40px;
					}
				}
			}
		}
	}
	&-box {
		position: relative;
		display: block;
		height: 100%;
		transition: all 0.3s ease;
		&:hover {
			@media screen and (hover: hover){
				animation: shadowFlash 0.5s ease forwards;
				.vaults-box {
					&__content {
						background: $colorDarkBg;
						animation: borderFlash 0.5s ease forwards;
						&:before {
							background: $colorGreen;
							transition: background 0.3s ease;
						}
					}
					&__details {
						.b-left {
							width: 80px;
							padding-left: 7px;
						}
						.trapeze {
							width: 40px;
						}
					}
				}
			}
		}
		&__content {
			position: relative;
			display: flex;
			flex-direction: column;
			background: $colorBg;
			min-height: 100%;
			padding: 0 20px;
			border: 1px solid rgba($colorWhite, 0.2);
			transition: border-color 0.3s ease, background 1s ease-in-out;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
			&:before {
				content: '';
				position: absolute;
				background: rgba($colorWhite, 0.2);
				right: -3px;
				bottom: 6px;
				width: 20px;
				height: 1px;
				transform: rotate(-45deg);
				transition: none;
			}
		}
		&__head {
			position: relative;
			display: flex;
			align-items: center;
			padding: 20px 0 24px;
			&:not(:last-child) {
				border-bottom: 1px solid $colorLightBg;
				transition: border-color 1s ease-in-out;
			}
		}
		&__img {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 66px;
			&:not(:last-child) {
				margin-right: 15px;
			}
			img {
				max-height: 30px;
				&:not(:last-child) {
					margin-bottom: 6px;
				}
			}
		}
		&__title {
			position: relative;
			color: rgba($colorWhite, 0.5);
			font-size: 12px;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			transition: color 1s ease-in-out;
			b, strong {
				display: block;
				color: $colorWhite;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: normal;
				margin-bottom: 5px;
				transition: color 1s ease-in-out;
			}
		}
		&__body {
			position: relative;
			display: flex;
			padding: 26px 0;
			&:not(:last-child) {
				border-bottom: 1px solid $colorLightBg;
				transition: border-color 1s ease-in-out;
			}
		}
		&__group {
			position: relative;
			display: flex;
			width: 100%;
			&-box {
				position: relative;
				flex: 1 0 0;
				color: rgba($colorWhite, 0.5);
				font-size: 12px;
				font-weight: 600;
				line-height: 1.3;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				padding: 0 16px;
				transition: color 1s ease-in-out;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				&:not(:last-child) {
					border-right: 1px solid $colorLightBg;
					transition: border-color 1s ease-in-out, color 1s ease-in-out;
				}
 				b, strong {
					display: block;
					color: $colorWhite;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: normal;
					margin-bottom: 5px;
					transition: color 1s ease-in-out;
				}
			}
		}
		&__foot {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 26px 0;
		}
		&__details {
			position: relative;
			display: flex;
			align-items: center;
			color: $colorWhite;
			font-size: 12px;
			font-family: 'Roboto', sans-serif;
			line-height: 1.16667;
			font-weight: 700;
			letter-spacing: 0.31em;
			text-transform: uppercase;
			.b-left {
				position: relative;
				width: 0;
				padding-left: 0;
				overflow: hidden;
				transition: all 0.5s ease;
				@media screen and (hover: none){
					width: 80px;
					padding-left: 7px;
				}
			}
		}
	}
}
