.box {
	position: relative;
	background: $colorBg;
	height: 100%;
	padding: 36px 32px 16px;
	transition: background 1s ease-in-out;
	&:before {
		content: '';
		position: absolute;
		background: $colorGreen;
		top: 0;
		left: 32px;
		width: 40px;
		height: 2px;
	}
	&-label {
		position: relative;
		font-size: 12px;
		font-weight: 700;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 0.2em;
		line-height: 1.16667;
		text-transform: uppercase;
		opacity: 0.5;
		transition: opacity 1s ease-in-out;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
	&-title {
		position: relative;
		display: flex;
		align-items: center;
		color: $colorGreen;
		font-size: 29px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 36px;
		}
		img {
			margin-right: 6px;
		}
	}
	&-heading {
		position: relative;
		font-size: 23px;
		font-weight: 600;
		line-height: 1.3;
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}
	&-table {
		position: relative;
		table {
			width: 100%;
			border-collapse: collapse;
			margin-bottom: -16px;
			tr {
				&:not(:last-child) {
					border-bottom: 1px solid $colorLightBg;
					transition: border-color 1s ease-in-out;
				}
				td, th {
					color: rgba($colorWhite, 0.3);
					font-size: 16px;
					line-height: 1.3;
					padding: 16px 0;
					transition: color 1s ease-in-out;
					&:last-child {
						text-align: right;
					}
					a {
						position: relative;
						display: inline-flex;
						align-items: center;
						color: $colorWhite;
						font-size: 12px;
						font-weight: 700;
						font-family: 'Roboto', sans-serif;
						line-height: 1.16667;
						letter-spacing: 0.31em;
						text-transform: uppercase;
						transition: color 1s ease-in-out;
						&:hover {
							color: $colorGreen;
							transition: color 0.3s ease;
						}
						img {
							max-width: 20px;
							margin-left: 4px;
						}
					}
				}
			}
		}
	}
	.heading {
		height: auto;
		padding: {
			top: 0;
			left: 0;
			right: 0;
			bottom: 24px;
		};
		margin-top: -12px;
		margin-bottom: 24px;
		&-subtitle {
			font-size: 16px;
		}
	}
	.zap {
		&-form {
			&__field {
				background: $colorDarkBg;
			}
			&__group {
				&:not(:last-child) {
					margin-bottom: 76px;
				}
			}
			&__arr {
				svg {
					top: -50px;
				}
			}
			&__button {
				.btn {
					width: 250px;
				}
			}
			.form {
				&-group {
					&:not(:last-child) {
						margin-bottom: 76px;
					}
				}
				&-select {
					&__box {
						background: $colorDarkBg;
					}
				}
			}
		}
	}
}
.charts {
	position: relative;
	height: 200px;
	canvas {
		width: 100% !important;
		height: 100% !important;
	}
}
