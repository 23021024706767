.foundry {
	position: relative;
	.d-flex {
		&.flex-column {
			height: 100%;
			.row {
				flex: 1 0 0;
			}
		}
	}
	.box {
		padding: {
			top: 24px;
			bottom: 24px;
		};
	}
	&-title {
		position: relative;
		max-width: 452px;
		font-size: 18px;
		font-weight: 700;
		line-height: 1.3;
		padding: {
			top: 8px;
			bottom: 8px;
			left: 32px;
		};
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}
	&-grid {
		position: relative;
	}
	&-line {
		position: relative;
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
		margin-right: -12px;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		&__label {
			position: relative;
			min-width: 90px;
			color: $colorGrey;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.3;
			padding-left: 16px;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				top: 50%;
				left: 0;
				height: calc(100% - 6px);
				width: 6px;
				margin-top: -2px;
				transform: translateY(-50%);
			}
		}
		&__title {
			position: relative;
			font-size: 23px;
			font-weight: 600;
			line-height: 1.15;
			word-break: break-word;
		}
	}
	&-chart {
		position: relative;
		display: flex;
		justify-content: space-between;
		&__head {
			position: relative;
			z-index: 2;
			flex: 1 0 0;
			&:not(:last-child) {
				margin-right: 24px;
			}
			.foundry-line {
				&__label {
					&:not(:last-child) {
						margin-bottom: 12px;
					}
				}
			}
		}
		&__body {
			position: relative;
			&:first-child {
				flex: 1 0 0;
			}
			canvas {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}
	&-form {
		position: relative;
		&__group {
			position: relative;
			&:not(:last-child) {
				margin-bottom: 68px;
			}
			.form {
				&-flex {
					.form {
						&-group {
							flex: 1 0 0;
							&:not(:first-child) {
								flex: none;
								width: 130px;
							}
							.btn {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	&-crypto {
		position: relative;
		//width: 138px;
		max-width: 180px;
		&__couple {
			position: relative;
			display: flex;
			align-items: center;
			color: $colorGrey;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.3;
			padding: 4px 10px;
			cursor: pointer;
			transition: all 0.3s ease;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
			&:hover,
			&.active {
				background: $colorDarkBg;
				color: $colorWhite;
			}
			&-color {
				position: relative;
				width: 6px;
				height: 12px;
				margin-top: -3px;
				&:not(:last-child) {
					margin-right: 8px;
				}
			}
			&-title {
				position: relative;
			}
		}
	}
	canvas {
		max-width: 100%;
	}
}
.chart {
	&-small {
		position: relative;
		width: 68px;
		height: 68px;
		margin-left: auto;
	}
	&-big {
		position: relative;
		width: 296px;
		height: 296px;
		margin: 0 auto;
	}
	&-dividends {
		position: relative;
		height: 230px;
		margin: 0 auto;
	}
	&-info {
		position: absolute;
		top: 50%;
		left: 50%;
		color: $colorGreen;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.3;
		transform: translate(-50%, -50%);
	}
	&-shadow {
		position: absolute;
		top: 50%;
		left: 50%;
		width: calc(100% + 22px);
		height: calc(100% + 22px);
		border: 48px solid #16191C;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		transition: border-color 1s ease-in-out;
	}
	&-table {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		table {
			tr {
				td, th {
					vertical-align: bottom;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.1;
					padding: 4px 3px;
					&:first-child {
						font-size: 12px;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-align: right;
						text-transform: uppercase;
						padding-left: 0;
						opacity: 0.5;
					}
					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
}
