::-webkit-input-placeholder {
	color: $colorGrey;
	opacity: 1;
	transition: color 1s ease-in-out;
}
::-moz-placeholder {
	color: $colorGrey;
	opacity: 1;
	transition: color 1s ease-in-out;
}
:-ms-input-placeholder {
	color: $colorGrey;
	opacity: 1;
	transition: color 1s ease-in-out;
}
:-moz-placeholder  {
	color: $colorGrey;
	opacity: 1;
	transition: color 1s ease-in-out;
}
input,
textarea {
	position: relative;
	background: transparent;
	color: $colorWhite;
	width: 100%;
	font-size: 14px;
	font-family: 'SF Pro Text', sans-serif;
  	border: 0;
	border-radius: 0;
	//padding: 0 16px;
	transition: color 1s ease-in-out, background 1s ease-in-out;
	appearance: none;
	&:hover {
		border-color: $colorDarkGrey;
	}
	&:active,
	&:focus {
		&:not(.btn) {
			&:not([disabled]) {
				&::-webkit-input-placeholder,
				&::-moz-placeholder,
				&:-ms-input-placeholder,
				&:-moz-placeholder {
					color: transparent;
				}
			}
		}
	}
	&[disabled],
	&.disabled{
		opacity: 0.5;
	}
}
input {
	height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
}
textarea {
	height: 120px;
	padding: 15px 15px;
	resize: none;
}
.input {
	&-lg {
		height: 60px !important;
	}
	&-md {
		height: 50px !important;
		font-size: 20px !important;
	}
	&-sm {
		height: 40px !important;
		font-size: 18px !important;
	}
}
.has {
	&-error {
		input,
		textarea {
			border-color: $colorRed !important;
		}
	}
	&-success {
		input,
		textarea {
			//border-color: $colorGreen !important;
		}
	}
}
label {
	display: block;
	margin-bottom: 0;
}
.form{
	&-title {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
	&-body {
		position: relative;
	}
	&-text {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
	&-group {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		label {
			color: rgba($colorWhite, 0.5);
			font-size: 12px;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			transition: color 1s ease-in-out;
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}
	}
	&-flex {
		display: flex;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		.form {
			&-group {
				&:not(:last-child) {
					margin-right: 16px;
					margin-bottom: 0;
				}
			}
		}
	}
	&-button {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	&-multiple {
		position: relative;
		background: $colorBg;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		padding: 5px 20px 5px 5px;
		clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
		transition: background 1s ease-in-out;
		.tabs {
			ul {
				background: $colorDarkBg;
			}
		}
	}
	&-select {
		position: relative;
		height: 40px;
		&.select-multiple {
			.form-select {
				&__box {
					&.show {
						//border-color: $colorGreen;
						//&:before {
						//	background: $colorGreen;
						//}
						//.form-select__line {
						//	height: 100%;
						//}
					}
				}
				&__toggle {
					min-height: 38px;
					height: auto;
				}
				&__title {
					overflow: visible;
				}
				&__scroll {
					ul {
						padding: 0 10px;
						li {
							//color: $colorWhite;
							padding-left: 0;
							padding-right: 20px;
							&:before {
								background: transparent;
								top: 14px;
								left: auto;
								right: 0;
								width: 12px;
								height: 12px;
								border-radius: 0;
								border: 1px solid $colorGrey;
							}
							&:after {
								content: '';
								position: absolute;
								background: $colorGreen;
								top: 18px;
								right: 4px;
								width: 4px;
								height: 4px;
								opacity: 0;
								transition: opacity 0.3s ease;
							}
							&.active {
								color: $colorGreen;
								&:before {
									border: 2px solid $colorGreen;
								}
								&:after {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
		&.select-short {
			.form-select {
				&__scroll {
					max-height: 80px;
				}
			}
		}
		&.select-flag {
			height: 50px;
			.form-select {
				&__line {
					height: 50px;
				}
				&__toggle {
					height: 50px;
					font-weight: 600;
				}
				&__list {
					padding-left: 0;
				}
				&__scroll {
					max-height: 200px;
					ul {
						li {
							color: $colorWhite;
							font-weight: 600;
							border-top: 0;
							padding-left: 10px;
							&:before {
								content: '';
								background: $colorWhite;
								top: 0;
								left: -15px;
								height: 100%;
								width: calc(100% + 10px);
								opacity: 0;
								transition: opacity 0.3s ease;
							}
							&.active {
								display: none;
							}
							&:hover {
								&:before {
									opacity: 0.1;
								}
							}
						}
					}
				}
			}
		}
		&__box {
			position: relative;
			background: $colorBg;
			border: 1px solid transparent;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
			transition: border-color 0.3s ease, background 1s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				right: -4px;
				bottom: 6px;
				width: 20px;
				height: 1px;
				transform: rotate(-45deg);
				transition: opacity 0.3s ease;
				opacity: 0;
			}
			&:hover {
				@media screen and (hover: hover) {
					&:not(.show) {
						border-color: $colorGreen;
						&:before {
							opacity: 1;
						}
					}
				}
			}
			&.show {
				border-color: $colorLightBg;
				&:before {
					background: $colorLightBg;
					opacity: 1;
				}
				.form-select {
					&__toggle {
						.icon {
							transform: rotateX(180deg);
						}
					}
					&__tag {
						pointer-events: auto;
					}
				}
			}
		}
		&__line {
			position: absolute;
			background: $colorGreen;
			top: 0;
			left: 0;
			width: 2px;
			height: 40px;
			transition: all 0.2s ease;
			pointer-events: none;
		}
		&__toggle {
			position: relative;
			display: flex;
			align-items: center;
			height: 38px;
			font-size: 16px;
			line-height: 1.3;
			padding: 0 15px;
			cursor: pointer;
			.icon {
				color: $colorGreen;
				font-size: 6px;
				margin-left: auto;
			}
		}
		&__title {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: calc(100% - 20px);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		&__tag {
			position: relative;
			background: rgba($colorGreen, 0.2);
			display: flex;
			align-items: center;
			height: 30px;
			color: $colorGreen;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			padding: 0 8px;
			margin: 2px 0 2px -8px;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
			pointer-events: none;
			&:not(:last-child) {
				margin-right: 13px;
			}
			.icon {
				font-size: 8px;
				letter-spacing: normal;
				margin-left: 8px;
			}
		}
		&__list {
			position: relative;
			display: none;
			padding: 0 5px;
		}
		&__scroll {
			position: relative;
			max-height: 164px;
			overflow-y: auto;
			ul {
				position: relative;
				padding: 0 5px;
				&::-webkit-scrollbar {
					width: 6px;
					border-radius: 3px;
				}
				&::-webkit-scrollbar-track {
					background: $colorBg;
				}
				&::-webkit-scrollbar-thumb {
					background: $colorDarkBg;
					width: 8px;
					border-radius: 0;
				}
				li {
					position: relative;
					display: flex;
					align-items: center;
					color: rgba($colorWhite, 0.5);
					font-size: 16px;
					line-height: 1.3;
					border-top: 1px solid $colorLightBg;
					padding: 10px 0 10px 30px;
					cursor: pointer;
					transition: color 1s ease-in-out, border-color 1s ease-in-out;
					&:hover {
						color: $colorWhite;
						transition: color 0.3s ease;
					}
					&.disabled,
					&[disabled] {
						pointer-events: none;
					}
					&.active {
						color: $colorWhite;
						&.form-select__all {
							display: none;
						}
					}
					&:before {
						content: '';
						position: absolute;
						background: $colorGreen;
						top: 18px;
						left: 10px;
						width: 3px;
						height: 3px;
					}
				}
			}
			.mCSB {
				&_container {
					&.mCS_no_scrollbar_y {
						&.mCS_y_hidden {
							margin-right: 0;
						}
					}
				}
				&_inside {
					& > .mCSB_container {
						margin-right: 28px;
					}
				}
				&_scrollTools {
					right: 12px;
				}
			}
		}
	}
	&-search {
		position: relative;
		height: 40px;
		&__box {
			position: relative;
			background: $colorBg;
			border: 1px solid transparent;
			clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%);
			transition: border-color 0.3s ease, background 1s ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: $colorGreen;
				right: -3px;
				bottom: 6px;
				width: 20px;
				height: 1px;
				transform: rotate(-45deg);
				transition: opacity 0.3s ease;
				opacity: 0;
			}
			&:hover {
				&:not(.show) {
					@media screen and (hover: hover){
						border-color: $colorGreen;
						&:before {
							opacity: 1;
						}
					}
				}
			}
			&.show {
				z-index: 1;
			}
		}
		&__line {
			position: absolute;
			background: $colorGreen;
			top: 0;
			left: 0;
			width: 2px;
			height: 40px;
			transition: all 0.3s linear;
			pointer-events: none;
		}
		&__toggle {
			position: relative;
			display: flex;
			align-items: center;
			height: 40px;
			font-size: 16px;
			line-height: 1.3;
			padding: 0 12px;
			cursor: pointer;
			.icon {
				color: $colorGreen;
				font-size: 20px;
				margin-right: 12px;
			}
			input {
				background: transparent;
				height: 40px;
				color: $colorWhite;
				font-size: 16px;
				border: 0;
				padding: 0;
				&::-webkit-input-placeholder {
					color: rgba($colorWhite, 0.5);
				}
				&::-moz-placeholder {
					color: rgba($colorWhite, 0.5);
				}
				&:-ms-input-placeholder {
					color: rgba($colorWhite, 0.5);
				}
				&:-moz-placeholder  {
					color: rgba($colorWhite, 0.5);
				}
			}
		}
		&__title {
			position: relative;
			width: calc(100% - 20px);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		&__list {
			position: relative;
			display: none;
			padding: 0 5px;
			ul {
				position: relative;
				max-height: 205px;
				overflow-y: auto;
				padding: 0 5px;
				&::-webkit-scrollbar {
					width: 6px;
					border-radius: 3px;
				}
				&::-webkit-scrollbar-track {
					background: $colorBg;
				}
				&::-webkit-scrollbar-thumb {
					background: $colorDarkBg;
					border-radius: 3px;
				}
				li {
					position: relative;
					color: rgba($colorWhite, 0.5);
					font-size: 16px;
					line-height: 1.3;
					border-top: 1px solid $colorLightBg;
					padding: 10px 0 10px 30px;
					cursor: pointer;
					transition: color 0.3s ease;
					&:hover {
						color: $colorWhite;
					}
					&.disabled,
					&[disabled] {
						pointer-events: none;
					}
					&.active {
						color: $colorWhite;
						&.form-select__all {
							display: none;
						}
					}
					&:before {
						content: '';
						position: absolute;
						background: $colorGreen;
						top: 18px;
						left: 10px;
						width: 3px;
						height: 3px;
					}
				}
			}
		}
	}
}
.checkbox{
	position: relative;
	display: inline-block;
	[type="checkbox"],
	[type="radio"] {
		position: absolute;
		left: -9999px;
		width: inherit;
		height: inherit;
		& ~ label {
			position: relative;
			display: inline-block;
			min-height: 20px;
			color: $colorBlack;
			line-height: 20px;
			padding-left: 20px;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				background: $colorWhite;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				border: 1px solid $colorGrey;
				transition: all 0.3s ease;
			}
			&:after {
				content: '';
				position: absolute;
				transition: all 0.3s ease;
				opacity: 0;
			}
			&:hover {
				&:before {
					border-color: $colorDarkGrey;
				}
			}
			&:not(:empty) {
				padding-left: 35px;
			}
		}
		&:disabled {
			& ~ label {
				&:before {
					opacity: 0.5;
				}
			}
		}
		&:checked {
			& ~ label {
				&:before {

				}
				&:after {
					opacity: 1;
				}
			}
			&:disabled {
				& ~ label {
					&:before {
						opacity: 0.5;

					}
					&:after {
						opacity: 0.5;
					}
				}
			}
		}
	}
	[type="checkbox"] {
		& ~ label {
			&:before{
				background: $colorGrey;
				top: 4px;
				left: 4px;
				width: 12px;
				height: 12px;
				border-radius: 0;
			}
			&:after {
				background: $colorGrey;
				top: 4px;
				left: 4px;
				width: 12px;
				height: 12px;
			}
		}
	}
	[type="radio"] {
		& ~ label {
			&:before {
				background: $colorGrey;
				top: 4px;
				left: 4px;
				width: 12px;
				height: 12px;
				border-radius: 50%;
			}
			&:after {
				border-radius: 50%;
			}
		}
	}
	&.has {
		&-error {
			[type="checkbox"],
			[type="radio"] {
				& ~ label {
					&:before {
						border-color: $colorRed !important;
					}
				}
			}
		}
	}
}
.switch {
	position: relative;
	display: inline-block;
	[type="checkbox"] {
		position: absolute;
		left: -9999px;
		width: inherit;
		height: inherit;
		& ~ label {
			position: relative;
			display: inline-block;
			min-height: 16px;
			color: rgba($colorWhite, 0.5);
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			padding-left: 16px;
			transition: color 1s ease-in-out;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				background: $colorDarkBg;
				top: 2px;
				left: 0;
				width: 16px;
				height: 10px;
				border: 1px solid rgba($colorWhite, 0.5);
				border-radius: 5px;
				transition: background 1s ease-in-out, border-color 1s ease-in-out;
			}
			&:after {
				content: '';
				position: absolute;
				background: $colorWhite;
				top: 4px;
				left: 8px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				transition: left 0.3s ease, background 1s ease-in-out;
			}
			&:hover {
				color: $colorWhite;
				transition: color 0.3s ease;
			}
			&:not(:empty) {
				padding-left: 24px;
			}
		}
		&:checked {
			& ~ label {
				color: $colorGreen;
				&:before {
					border-color: $colorGreen;
				}
				&:after {
					left: 2px;
				}
				&:hover {
					color: $colorWhite;
				}
			}
		}
	}
}
.help-block {
	position: relative;
	color: $colorRed;
	font-size: 12px;
	line-height: 16px;
	padding: 5px 20px;
}
