.blog {
	position: relative;
	&-tabs {
		position: relative;
		border-bottom: 1px solid #283037;
		transition: border-color 1s ease-in-out;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		&:not(:last-child) {
			margin-bottom: 50px;
		}
		ul {
			display: flex;
			li {
				position: relative;
				color: $colorGrey;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				white-space: nowrap;
				padding: 0 12px 6px;
				cursor: pointer;
				transition: color 1s ease-in-out;
				&:hover {
					color: $colorWhite;
					transition: color 0.3s ease;
				}
				&.active {
					color: $colorWhite;
					&:before {
						transform-origin: left;
						transform: scaleX(1);
					}
				}
				&:before {
					content: '';
					position: absolute;
					background: $colorGreen;
					left: 0;
					bottom: -1px;
					width: 100%;
					height: 1px;
					transform-origin: right;
					transform: scaleX(0);
					transition: transform 0.3s ease-out;
				}
			}
		}
	}
	&-content {
		position: relative;
	}
		&-list {
			position: relative;
			display: none;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 76px;
			grid-row-gap: 60px;
			&.show {
				display: grid;
			}
		}
		&-box {
			position: relative;
			display: block;
			&:hover {
				.blog-box {
					&__img {
						&:before {
							transform-origin: left;
							transform: scaleX(1);
						}
					}
					&__category,
					&__date {
						color: $colorWhite;
					}
					&__title {
						color: $colorGreen;
						transition: color 0.3s ease;
					}
				}
			}
			&__img {
				position: relative;
				padding-top: 56.18%;
				overflow: hidden;
				&:not(:last-child) {
					margin-bottom: 20px;
				}
				&:before {
					content: '';
					position: absolute;
					background: $colorGreen;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2px;
					transform-origin: right;
					transform: scaleX(0);
					transition: transform 0.3s ease-out;
					z-index: 1;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			&__category {
				position: relative;
				color: $colorGrey;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				padding-left: 10px;
				transition: color 1s ease-in-out;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				&:before {
					content: '';
					position: absolute;
					background: $colorGreen;
					top: 2px;
					bottom: 2px;
					left: 0;
					width: 2px;
				}
			}
			&__title  {
				position: relative;
				color: $colorWhite;
				font-size: 18px;
				font-weight: 600;
				line-height: 1.3;
				transition: color 1s ease-in-out;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
			&__date {
				position: relative;
				color: $colorGrey;
				font-size: 12px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
				line-height: 1.16667;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				padding-left: 10px;
				transition: color 1s ease-in-out;
				&:before {
					content: '';
					position: absolute;
					background: $colorGreen;
					top: 2px;
					bottom: 2px;
					left: 0;
					width: 2px;
				}
			}
		}
}
