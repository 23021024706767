.main {
	position: relative;
	padding-bottom: 16px;
	&.sidebar-hide {
		.main {
			&-sidebar {
				width: 0;
			}
			&-content {
				width: 100%;
			}
		}
	}
	&-inner {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	&-sidebar {
		position: sticky;
		top: 62px;
		left: 0;
		width: 230px;
		max-height: calc(100vh - 62px);
		transition: width 0.3s ease;
		overflow-x: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 6px;
			border-radius: 3px;
		}
		&::-webkit-scrollbar-track {
			background: $colorLightBg;
		}
		&::-webkit-scrollbar-thumb {
			background: $colorDarkBg;
			border-radius: 3px;
		}
		&__panel {
			position: relative;
			display: none;
		}
	}
	&-content {
		position: relative;
		width: calc(100% - 236px);
		transition: width 0.3s ease;
		&:before {
			content: '';
			position: absolute;
			background: $colorLightBg;
			top: 0;
			left: 0;
			right: 0;
			height: 1px;
			transition: opacity 1s ease-in-out;
		}
		&__grid {
			position: relative;
			padding-top: 54px;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
		.row {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
			.col,
			[class*="col-"] {
				min-height: 100%;
			}
		}
		.col,
		[class*="col-"] {
			&.flex-column {
				.row {
					flex: 1 0 0;
				}
			}
		}
	}
}
