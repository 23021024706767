@font-face
	font-family: "svgfont"
	src: url('fonts/svgfont.eot')
	src: url('fonts/svgfont.eot?#iefix') format('eot'), url('fonts/svgfont.woff') format('woff'), url('fonts/svgfont.woff2') format('woff2'), url('fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-close: "\EA01"
$icon-i-home: "\EA02"
$icon-i-menu: "\EA03"
$icon-i-briefcase: "\EA04"
$icon-i-wireless-charging: "\EA05"
$icon-i-coins: "\EA06"
$icon-i-information: "\EA07"
$icon-i-alert: "\EA08"
$icon-i-arr-right-up: "\EA09"
$icon-i-carret-down: "\EA0A"
$icon-i-close: "\EA0B"
$icon-i-crescent: "\EA0C"
$icon-i-sun: "\EA0D"
$icon-i-earth: "\EA0E"
$icon-i-funds: "\EA0F"
$icon-i-gift: "\EA10"
$icon-i-line-chart: "\EA11"
$icon-i-safe: "\EA12"
$icon-i-search: "\EA13"
$icon-i-wallet: "\EA14"
$icon-i-medium: "\EA15"
$icon-i-reddit: "\EA16"
$icon-i-telegram: "\EA17"
$icon-i-twitch: "\EA18"
$icon-i-twitter: "\EA19"
$icon-i-arr-right-down: "\EA1A"
$icon-i-flashlight: "\EA1B"
$icon-i-exchange: "\EA1C"
$icon-i-coin: "\EA1D"
$icon-i-medal: "\EA1E"
$icon-i-profile: "\EA1F"
$icon-i-quote: "\EA20"
$icon-i-chat-delete: "\EA21"
$icon-i-share: "\EA22"
$icon-i-discord: "\EA23"
$icon-i-menu-open: "\EA24"
$icon-i-filter: "\EA25"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-close:before
	content: $icon-close
.icon-i-home:before
	content: $icon-i-home
.icon-i-menu:before
	content: $icon-i-menu
.icon-i-briefcase:before
	content: $icon-i-briefcase
.icon-i-wireless-charging:before
	content: $icon-i-wireless-charging
.icon-i-coins:before
	content: $icon-i-coins
.icon-i-information:before
	content: $icon-i-information
.icon-i-alert:before
	content: $icon-i-alert
.icon-i-arr-right-up:before
	content: $icon-i-arr-right-up
.icon-i-carret-down:before
	content: $icon-i-carret-down
.icon-i-close:before
	content: $icon-i-close
.icon-i-crescent:before
	content: $icon-i-crescent
.icon-i-sun:before
	content: $icon-i-sun
.icon-i-earth:before
	content: $icon-i-earth
.icon-i-funds:before
	content: $icon-i-funds
.icon-i-gift:before
	content: $icon-i-gift
.icon-i-line-chart:before
	content: $icon-i-line-chart
.icon-i-safe:before
	content: $icon-i-safe
.icon-i-search:before
	content: $icon-i-search
.icon-i-wallet:before
	content: $icon-i-wallet
.icon-i-medium:before
	content: $icon-i-medium
.icon-i-reddit:before
	content: $icon-i-reddit
.icon-i-telegram:before
	content: $icon-i-telegram
.icon-i-twitch:before
	content: $icon-i-twitch
.icon-i-twitter:before
	content: $icon-i-twitter
.icon-i-arr-right-down:before
	content: $icon-i-arr-right-down
.icon-i-flashlight:before
	content: $icon-i-flashlight
.icon-i-exchange:before
	content: $icon-i-exchange
.icon-i-coin:before
	content: $icon-i-coin
.icon-i-medal:before
	content: $icon-i-medal
.icon-i-profile:before
	content: $icon-i-profile
.icon-i-quote:before
	content: $icon-i-quote
.icon-i-chat-delete:before
	content: $icon-i-chat-delete
.icon-i-share:before
	content: $icon-i-share
.icon-i-discord:before
	content: $icon-i-discord
.icon-i-menu-open:before
	content: $icon-i-menu-open
.icon-i-filter:before
	content: $icon-i-filter